<template>
  <div v-if="db3.config.pages.documents == true">
    <div
      class="doc-bg"
      :style="`
        background-image: url(${db3.documentos.img});`"
    >
      <div class="bg-semi-transparent"></div>
      <h1>
        {{ db3.documentos.title }}
      </h1>
      <i class="bi bi-caret-up-fill"></i>
    </div>
    <div class="accordion Docs-box" id="accordionExample">
      <div
        v-for="(ul, index) in db3.documentos.list"
        :key="index"
        class="accordion-item"
      >
        <h2 class="accordion-header">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${index}`"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <i class="bi bi-file-earmark-text" style="font-size: 1.4rem"></i>
            <span style="margin-left: 10px; font-size: 1.4rem">{{
              ul.category
            }}</span>
          </button>
        </h2>
        <div
          :id="`collapse${index}`"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div v-for="li in ul.docs" :key="li.index" style="margin: 5px">
              <a
                :href="li.doc_link"
                target="__blank"
                style="
                  margin-top: 10px !important;
                  text-decoration: none;
                  color: black;
                  font-size: 1.1rem;
                "
              >
                <i
                  style="font-size: 1.35rem; margin-right: 4px"
                  class="bi bi-file-earmark-arrow-down"
                ></i>
                {{ li.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Footer reveal Rtop">
    <GlobalFooter />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { data } from "../App.vue";
import GlobalFooter from "../components/GlobalFooter.vue";

export default defineComponent({
  name: "JRDocuments",
  components: {
    GlobalFooter,
  },
  data() {
    return {
      db3: data,
      Palet: "DarkBlue",
    };
  },
});
</script>

<style scoped>
.accordion-button {
  background-color: white !important;
  --accordion-btn-focus-border-color: white !important;
  --accordion-btn-focus-box-shadow: white !important;
}
.doc-bg i {
  position: absolute;
  bottom: -65px;
  color: white;
  font-size: 100px;
  z-index: 5;
}
.doc-bg {
  height: 450px;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  align-items: center;
  background-attachment: fixed;
  justify-content: center;
  background-size: cover;
}
.doc-bg h1 {
  z-index: 5;
  font-size: 5rem;
  position: relative;
  color: white;
}
.Docs-box {
  padding: 130px;
}

@media (max-width: 750px) {
  .Docs-box {
    padding-inline: 40px;
  }
  @media (max-width: 500px) {
    .Docs-box {
      padding-inline: 10px !important;
    }
    .doc-bg h1 {
      font-size: 2.9rem !important;
    }
  }
}

.bg-semi-transparent {
  background-color: rgba(0, 0, 0, 0.573);
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 450px;
}
</style>
