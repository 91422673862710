<template>
  <div v-show="db3.config.pages.weare">
    <div
      class="doc-bg"
      :style="`
        background-image: url(${db3.quem_somos.img});`"
    >
      <div class="bg-semi-transparent"></div>
      <div style="max-width: 1200px">
        <h1 style="font-size: 3.9rem; margin-bottom: 20px">
          {{ db3.quem_somos.title }}
        </h1>
        <p>
          {{ db3.quem_somos.text }}
        </p>
      </div>

      <i class="bi bi-caret-up-fill"></i>
    </div>
    <div class="sobre-box"></div>
  </div>
  <div class="sup-box">
    <div style="max-width: 1200px">
      <div>
        <h1 class="h1-qs">
          {{ db3.quem_somos.sobre.title }}
        </h1>
        <div v-for="items in db3.quem_somos.sobre.parag" :key="items.index">
          <li style="color: rgb(74, 74, 74) !important">
            <strong style="color: rgb(74, 74, 74) !important">
              {{ items.destach_text }}
            </strong>
            {{ items.text }}
          </li>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <div style="max-width: 1200px">
          <h1 class="h1-qs">
            {{ db3.quem_somos.sobre2.title }}
          </h1>
          <img
            style="display: flex; margin: 0 auto"
            :src="db3.quem_somos.sobre2.img"
            alt=""
          />
          <div v-for="items in db3.quem_somos.sobre2.parag" :key="items.index">
            <h3 style="color: rgb(74, 74, 74) !important; text-align: center">
              {{ items.title }}
            </h3>
            <li
              v-for="(items, index) in items.texts"
              :key="index"
              style="color: rgb(74, 74, 74) !important; text-a: center"
            >
              <strong style="color: rgb(74, 74, 74) !important; text-a: center">
                {{ items.destach_text }}
              </strong>
              {{ items.text }}
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br /><br /><br />
  <div class="Footer reveal Rtop">
    <GlobalFooter />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { data } from "../App.vue";
import GlobalFooter from "../components/GlobalFooter.vue";

export default defineComponent({
  name: "JRWeAre",
  components: {
    GlobalFooter,
  },
  data() {
    return {
      db3: data,
      Palet: "DarkBlue",
    };
  },
});
</script>

<style scoped>
.h1-qs {
  padding-bottom: 35px !important;
  margin-top: 40px;
  text-align: center;
  color: rgb(74, 74, 74) !important;
}
.accordion-button {
  background-color: white !important;
  --accordion-btn-focus-border-color: white !important;
  --accordion-btn-focus-box-shadow: white !important;
}
h1,
h2,
h3,
h4 {
  font-family: Poppins-Medium;
}
.doc-bg i {
  position: absolute;
  bottom: -65px;
  color: white;
  font-size: 100px;
  z-index: 5;
}
.doc-bg {
  height: 109vh;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  background-attachment: fixed;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  background-size: cover;
}
.doc-bg h1 {
  z-index: 5;
  font-size: 5rem;
  position: relative;
  color: white;
}
.doc-bg p {
  z-index: 5;
  font-size: 1.4rem;
  position: relative;
  color: white;
}
.Docs-box {
  padding: 130px;
}

@media (max-width: 850px) {
  .Docs-box {
    padding-inline: 40px;
  }
  .card {
    width: 90% !important;
  }
  @media (max-width: 750px) {
    .Docs-box {
      padding-inline: 10px !important;
    }
    .doc-bg h1 {
      font-size: 3.9rem !important;
      text-align: center;
    }
  }
}

p,
a,
span,
strong {
  font-family: Poppins-light;
  margin-top: 5px;
  font-size: 1.2rem;
  color: rgb(57, 57, 57);
}

.bg-semi-transparent {
  background-color: rgba(0, 0, 0, 0.737);
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 109vh;
}
.sup-text {
  padding: 15px;
  width: 850px;
  text-align: center;
  font-family: "Poppins-Regular";
}
.sobre-box {
  display: flex;
  justify-content: center;
}
.sup-box {
  display: flex;
  margin-top: 40px;
  justify-content: center;
}

.card-box {
  display: flex;
  margin-top: 5px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.card {
  width: 800px;
  margin-inline: 20px;
}
</style>
