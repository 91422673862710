import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContractView from '../views/ContractView.vue' 
import JRDocumentsView from '../views/JRDocuments.vue' 
import JRSuportView from '../views/JRSuport.vue' 
import JRWeAreView from '../views/JRWeAre.vue' 

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:tag?',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/contract/:id?',
    name: 'contract',
    component: ContractView
  },
  {
    path: '/documentos',
    name: 'documentos',
    component: JRDocumentsView
  },
  {
    path: '/suporte-tecnico',
    name: 'suporte-tecnico',
    component: JRSuportView
  },
  {
    path: '/quem-somos',
    name: 'quem-somos',
    component: JRWeAreView
  }
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),  
  history: createWebHashHistory(),  
  routes
})

export default router
