
import { defineComponent } from "vue";
import ContractPage from "@/components/ContractPage.vue"; // @ is an alias to /src

export default defineComponent({
  name: "ContractView",
  components: {
    ContractPage,
  },
});
