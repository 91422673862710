<template>
  <div class="footernav">
    <footer
      :style="`background-color: ${db.color_palet[Palet].destach_areas} !important; }`"
      class="footernav text-center text-lg-start text-muted"
    >
      <!-- Section: Social media -->
      <section
        :style="`background-color: ${db.color_palet[Palet].destach_areas} !important; }`"
        class="d-flex intro-sec justify-content-lg-between p-4 border-bottom"
      >
        <!-- Left -->
        <div class="me-5 d-none d-lg-block">
          <span>{{ Footer.link.title }}</span>
        </div>
        <!-- Left -->

        <!-- Right -->
        <div>
          <a
            v-for="link in Footer.link.media"
            :key="link.index"
            :href="link.link"
            target="_blank"
            class="me-4 link-secondary"
          >
            <i
              class="bi start-icon"
              :class="link.icon"
              data-toggle="tooltip"
              data-placement="top"
              :title="link.tooltip"
            ></i>
          </a>
        </div>
        <!-- Right -->
      </section>
      <!-- Section: Social media -->

      <!-- Section: Links  -->
      <section
        class=""
        :style="`background-color: ${db.color_palet[Palet].destach_areas} !important; }`"
      >
        <div
          :style="`background-color: ${db.color_palet[Palet].destach_areas} !important; }`"
          class="container link-section text-center text-md-start mt-5"
        >
          <!-- Grid row -->
          <div class="row mt-3">
            <!-- Grid column -->
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <!-- Content -->
              <h6 class="text-uppercase fw-bold mb-4">
                {{ Footer.main_column.title }}
              </h6>
              <p>
                {{ Footer.main_column.parag }}
              </p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div
              v-for="column in Footer.columns"
              :key="column.index"
              class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4"
            >
              <!-- Links -->
              <h6 class="text-uppercase fw-bold mb-4">{{ column.title }}</h6>
              <div>
                <p v-for="item in column.itens" :key="item">
                  <a
                    :href="item.link"
                    target="_blank"
                    :class="item.link === '' ? 'linkoff' : ''"
                    class="text-reset"
                    >{{ item.title }}</a
                  >
                </p>
              </div>
            </div>
          </div>
          <!-- Grid row -->
        </div>
      </section>
      <!-- Section: Links  -->

      <!-- copyright -->
      <div
        class="text-center p-4 copyright-section"
        :style="`background-color: ${db.color_palet[Palet].destach_areas} !important; }`"
      >
        {{ Footer.copyright.copyright }}
        <a
          class="text-reset fw-bold"
          :class="Footer.copyright.link === '' ? 'linkoff' : ''"
          target="_blank"
          :href="Footer.copyright.link"
          >{{ Footer.copyright.title }}</a
        >
      </div>
      <!-- copyright -->
    </footer>
    <!-- Footer -->
  </div>
</template>
  
<script>
import { data } from "../App.vue";
export default {
  name: "GlobalFooter",
  data() {
    return {
      Footer: [],
      Palet: "DarkBlue",
      pl: data.color_palet,
      db: data,
    };
  },
  created() {
    this.Footer = this.db.footer;
    this.Palet = this.db.color_palet.Select;
  },
};
</script>

<style>
.linkoff {
  text-decoration: none !important;
}

.footernav {
  display: block;
}

.intro-sec {
  color: white;
  font-family: Poppins-Medium;
  background-color: #1d1d42;
}

.start-icon {
  color: white;
}

.link-section {
  color: white;
  background-color: #1d1d42;
}

.footernav {
  color: white;
  filter: brightness(95%);
}

.copyright-section {
  color: white;
  filter: brightness(90%);
}
</style>
  