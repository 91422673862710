<template>
  <div class="Nav">
    <div>
      <nav
        :class="{ 'navbar-scrolled fixed-top onTop': navbarScrolled }"
        class="navbar navbar-expand-lg navbar-dynamic navbar-scrolled fixed-top"
        ref="content"
        style="background-color: #ffffff"
        data-bs-theme="dark"
      >
        <div class="container">
          <button
            class="navbar-toggler"
            type=""
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <i class="bi bi-bi bi-three-dots dot-icon"></i>
          </button>
          <div class="d-flex align-items-center logo-div me-auto">
            <a to="/" class="navbar-brand me-1" href="/">
              <img :src="db.navbar.img" alt="" height="45" />
            </a>
          </div>
          <div
            div
            class="offcanvas offcanvas-start"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <button
              class="navbar-toggler navbar-toggler-2"
              type=""
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <i class="bi bi-x-lg x-icon"></i>
            </button>
            <!-- BUTTONS V-FOR -->
            <hr class="hr-display" />

            <div :key="button.index" v-for="button in db.navbar.buttons">
              <div v-if="button.type !== 'DROPDOWN'" class="nav-item">
                <a
                  class="item-nav dropdown-button"
                  :target="button.type == 'LINK' ? '__blank' : ''"
                  :href="button.value"
                >
                  {{ button.name }}
                </a>
              </div>
            </div>
            <div :key="button.index" v-for="button in db.navbar.buttons">
              <div v-if="button.type == 'DROPDOWN'" class="nav-item dropdown">
                <a
                  class="item-nav dropdown-button"
                  href="#"
                  role="button"
                  aria-expanded="false"
                >
                  {{ button.name }} <i class="bi bi-chevron-down"></i>
                </a>
                <ul
                  class="dropdown-menu bg-dropdown"
                  style="
                    box-shadow: -1px 10px 20px 0px #00000066;
                    background-color: white;
                    padding: 10px;
                    padding-inline: 15px;
                    margin-top: 2px;
                  "
                >
                  <li
                    class="li-dropdown"
                    v-for="list in button.value"
                    :key="list.index"
                  >
                    <a
                      :style="`--underline-color:
                          ${db.color_palet[Palet].destach_areas}`"
                      class="dropdown-item hover-dropdown"
                      style="color: #373737 !important; font-size: 1.2rem"
                      :target="list.type == 'LINK' ? '__blank' : ''"
                      :href="
                        list.type == 'LINK' ? list.value : '/#/' + list.value
                      "
                      >{{ list.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              div
              class="offcanvas offcanvas-end"
              tabindex="-1"
              style="justify-content: flex-end"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div v-if="db.config.layout.plans == true">
                <button class="button-navbar2" @click="goTo('price')">
                  Contratar
                </button>
              </div>
              <div>
                <button
                  :style="`background-color: ${db.color_palet[Palet].destach_areas};`"
                  class="button-navbar"
                  @click="whatsapp()"
                >
                  <i class="bi bi-telephone-fill"> </i> Fale Conosco
                </button>
              </div>
            </div>

            <div style="display: none" class="showPhone">
              <div>
                <div class="base-collumns">
                  <hr />
                  <div
                    v-for="column in Footer.columns"
                    :key="column.index"
                    class=""
                  >
                    <!-- Links -->
                    <h6 class="text-uppercase fw-bold mb-4 base-collumns">
                      {{ column.title }}
                    </h6>
                    <div>
                      <p v-for="item in column.itens" :key="item">
                        <a
                          :href="item.link"
                          target="_blank"
                          :class="item.link === '' ? 'linkoff' : ''"
                          class="text-reset base-collumns"
                          >{{ item.title }}</a
                        >
                      </p>
                    </div>
                    <hr class="hr-block" style="display: none" />
                  </div>
                </div>
              </div>
              <div style="margin-left: 20px">
                <div>
                  <a
                    v-for="link in Footer.link.media"
                    :key="link.index"
                    :href="link.link"
                    class="me-4 link-secondary"
                  >
                    <i
                      class="bi start-icon"
                      :class="link.icon"
                      data-toggle="tooltip"
                      target="_blank"
                      data-placement="top"
                      :title="link.tooltip"
                    ></i>
                  </a>
                </div>
              </div>
              <div class="text-center p-4 copyright-section2">
                {{ Footer.copyright.copyright }}
                <a
                  class="text-reset fw-bold"
                  :class="Footer.copyright.link === '' ? 'linkoff' : ''"
                  target="_blank"
                  :href="Footer.copyright.link"
                  >{{ Footer.copyright.title }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { data } from "../App.vue";
export default {
  name: "NavBar",
  data() {
    return {
      db: data,
      Footer: [],
      navbarScrolled: false,
      Palet: "DarkBlue",
    };
  },
  created() {
    this.Footer = this.db.footer;
    this.Palet = this.db.color_palet.Select;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    whatsapp() {
      window.open(this.db.model_zap.link_direction, "_blank");
    },
    handleScroll() {
      this.navbarScrolled = window.scrollY > 450;
    },
    goTo(accessAction) {
      this.$router.push({
        name: "home",
        hash: "#home",
      });
      if (accessAction == "price") {
        this.$router.push({
          name: "home",
          hash: "#price",
        });
      }
      if (accessAction == "resoucer") {
        this.$router.push({
          name: "home",
          hash: "#resoucer",
        });
      }
    },
  },
};
</script>

<style scoped>
.linkoff {
  text-decoration: none !important;
}

.navbar-scrolled {
  height: 10vh !important;
  max-height: 70px;
  min-height: 60px;
}

.footernav {
  display: block;
}

.intro-sec {
  color: #113c78;
  font-family: Poppins-Medium;
  background-color: #1414141c;
}

.base-collumns {
  margin: 5px !important;
  padding: 6px;
  width: 100%;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.button-navbar {
  transition: all 0.3s ease;
  border-radius: 19px !important;
}
.button-navbar:hover {
  background: rgba(0, 0, 0, 0.036) !important;
  color: #3c3c3c !important;
  border-color: #3c3c3c !important;
}

.dropdown > .dropdown-toggle:active {
  pointer-events: none;
}

.base-collumns2 {
  margin: 4px !important;
  padding: 5px;
  width: 100%;
}

.start-icon {
  color: white;
}

.link-section {
  color: white;
  background-color: #1414141c;
}

.footernav {
  color: white;
  background-color: #1414141c;
}

.copyright-section2 {
  color: white;
  background-color: #0000001c;
  position: absolute;
  bottom: 0;
}
.buttonlink {
  transition: all 0.5s;
  background: none;
  border: 1px #ffffff !important;
  transition: all 0.5s;
  color: black;
}

.access-items {
  color: #010409;
  text-decoration: none !important;
  font-family: Poppins-Regular;
  border: 1px double #ffffff;
  transition: all 0.5s;
  font-size: 18px;
}

.bi-whatsapp {
  font-size: 1.25rem;
}

html {
  scroll-behavior: smooth;
}

.hover-dropdown:hover {
  background-color: #f4f4f4 !important;
  color: #000000 !important;
}

.hover-dropdown:focus {
  background-color: #d2d2d2ca !important;
  color: #000000 !important;
}

.navbar-toggler {
  position: absolute;
  left: 30px;
}

nav {
  transition: all 1.4s ease;
  position: fixed;
  width: 100%;
  box-shadow: 1px 1px 11px 1px rgba(0, 0, 0, 0.13) !important;
}
@media (max-width: 992px) {
  .container {
    max-width: 100% !important;
    padding: 0 !important;
  }
  .navbar-brand {
    margin: 0 auto 0 auto 0;
  }

  /* MAX HEIGHT FOOTER */
  @media (max-height: 798px) {
    .copyright-section2 {
      color: white;
      background-color: #0000001c;
      position: initial !important;
      bottom: 0;
    }
  }

  .logo-div {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  nav {
    position: fixed !important;
    width: 100%;
    height: 80px !important;
    box-shadow: 6px 9px 12px 5px rgba(0, 0, 0, 0.283);
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .navbar-collapse {
    background-color: #1351b3;
    box-shadow: 1px 20px 10px 1px rgba(0, 0, 0, 0.188);
    width: 60%;
    flex-grow: 0 !important;
    flex-basis: 0 !important;
  }
  .collapsing {
    height: 80px !important;
    width: 0px !important;
    overflow: hidden !important;
    transition: all 0.3s;
  }
  .offcanvas {
    display: block !important;
    max-width: 85% !important;
    width: 240px !important;
    z-index: 99991 !important;
  }
  .item-nav {
    color: rgba(255, 255, 255, 0.851) !important;
    margin-left: 17px !important;
    margin-inline: 0px;
    margin-top: 5px !important;
    display: block;
  }

  .buttons-vfor {
    margin-top: 20px;
  }
  .hr-display {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .dropdown-menu {
    position: inherit;
    border-radius: none;
    margin-bottom: 10px;
  }
  .offcanvas-backdrop {
    z-index: 9999 !important;
  }
  .nav-group {
    display: block !important;
  }
  .buttons-icons {
    display: none !important;
  }

  .access-buttons {
    margin-top: 50px;
  }
  .buttonlink {
    width: 95%;
    margin: 5px;
    text-align: left !important;
    color: white !important;
  }
  .access-items {
    color: rgba(255, 255, 255, 0.761) !important;
    border-color: white !important;
  }
  .showPhone {
    display: block !important;
  }
  .tsToggle {
    display: block !important;
  }
  .offcanvas {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
  }
  h6 {
    color: white !important;
  }
  .bg-dropdown {
    background-color: var(--bs-dropdown-bg) !important;
    color: black !important;
    --bs-dropdown-bg: white !important;
  }
  .nav-link.show {
    color: black !important;
  }
  .nav-link:focus {
    color: black !important;
  }

  p:hover,
  a:hover {
    color: white;
  }
}
.item-nav {
  color: rgba(0, 0, 0, 0.72);
  font-family: Poppins-Regular;
  text-decoration: none;
  transition: all 0.4s ease;
  position: relative;
  padding: 3px;
  margin-inline: 14px;
  overflow: hidden;
}

.item-nav:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%; /* Começa no meio do texto */
  width: 0; /* Inicialmente, a largura é 0 para que a linha esteja oculta */
  height: 1.4px; /* Espessura da linha */
  background-color: var(--underline-color, #767676); /* Cor da linha */
  transform: translateX(-50%); /* Centraliza a linha horizontalmente */
  transition: width 0.3s ease;
}

.item-nav:hover::after {
  width: 100%;
}

.ul-right {
  display: flex;
}
.button-navbar {
  width: auto;
  padding: 2px;
  padding-top: 5px;
  padding-inline: 20px;
  border: #c7c7c7c7 solid 1px;
  background-color: transparent;
  border-radius: 19px;
  font-family: Poppins-Regular;
  color: rgb(255, 255, 255);
  margin: 5px;
  transition: all 0.6s;
  height: auto;
}
.button-navbar2 {
  width: auto;
  padding: 2px;
  padding-top: 5px;
  padding-inline: 20px !important;
  border: #868686ee solid 1px;
  background-color: transparent;
  border-radius: 19px;
  font-family: Poppins-Regular;
  margin: 5px;
  transition: all 0.6s;
  height: auto;
  color: rgb(58, 58, 58);
}
.button-navbar i,
.button-navbar2 i {
  margin-right: 5px;
  font-size: 0.9rem;
}

.navbar-toggler-2 {
  position: absolute;
  top: 10px !important;
  border-block: inherit;
  right: 1px !important;
  left: auto !important;
}

.hr-block {
  display: block !important;
}

.x-icon {
  color: rgba(255, 255, 255, 0.832);
  font-size: 1.3rem;
  transition: all 0.3s;
}

.dot-icon {
  color: rgba(0, 0, 0, 0.832);
  font-size: 2.3rem;
  transition: all 0.3s;
}

.navbar-toggler:hover {
  animation: rotateX 1s;
}
.tsToggle {
  display: none;
}

@keyframes rotateX {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.navbar-brand:hover {
  transform: scale(85%);
}

.navbar-brand {
  transition: all 0.5s;
}

.navbar-toggler:focus {
  box-shadow: 0px 0px 0px #ffffff00 !important;
}
.navbar-toggler {
  border: none !important;
}
.nav-group {
  display: flex;
}
.offcanvas {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
}

.icon {
  font-size: 1.25rem;
  font-weight: bold;
}

.container {
  max-width: 1150px !important;
}

.navbar-scrolled {
  position: fixed;
  box-shadow: 6px 9px 12px 5px rgba(0, 0, 0, 0.283);
}

.navbar-scrolled .nav-buttons {
  display: block !important;
}

@font-face {
  font-family: "Poppins-Black";
  src: url("fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Quicksand";
  src: url("fonts/Quicksand_Light.otf");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("fonts/Poppins-Regular.ttf");
}
</style>

  