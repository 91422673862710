<template>
  <div
    class="background"
    :style="{ 'background-image': `url(${rg.background.img})` }"
  >
    <div class="">
      <!-- Conteudo da pagina -->
      <div class="margin-top" style="margin-top: 5.7rem"></div>
      <div
        class=""
        :class="{
          container: verifyForm.active !== true,
          containerM: verifyForm.active === true,
        }"
      >
        <div class="">
          <!-- FORMULÁRIO DE CPF/CNPJ -->

          <form
            v-show="rgiform.active"
            class="formv1 forms"
            @submit.prevent="go((goTo = 2))"
          >
            <!--Titulo -->
            <div style="display: flex; justify-content: center"></div>
            <h1 class="rec-titleh4 font-medium">{{ rg.tab1.parag }}</h1>
            <div>
              <div class="form-group-pessoa">
                <div
                  class="display-flex form-group"
                  style="height: 40px; margin-bottom: 0.5rem"
                >
                  <div class="div-icon">
                    <i
                      class="bi bi-person"
                      style="font-size: 1rem; color: rgba(46, 46, 46, 0.548)"
                    ></i>
                  </div>
                  <div
                    class="form-group"
                    style="margin-bottom: 0 !important; width: 100%"
                  >
                    <input
                      class="OAJDNIOOI"
                      :class="{ 'warning-cpf': alert.alertFocus === 'cpf' }"
                      v-maska
                      :data-maska="
                        rgiform.pessoa === 'fisica'
                          ? '###.###.###-##'
                          : '##.###.###/####-##'
                      "
                      id="rgi"
                      type="pessoa"
                      v-model="rgiform.rgi"
                      placeholder=" "
                    />
                    <label
                      :class="{ 'warning-cpf': alert.alertFocus === 'cpf' }"
                      class="label-input-text"
                      for="pessoa"
                      >{{ rgiform.pessoa !== "fisica" ? "CNPJ" : "CPF/CNPJ" }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Botões -->
            <div
              class="display-flex button-box"
              style="margin-top: -25px !important"
            >
              <button
                :style="`background-color: ${pl[Palet].destach_areas};`"
                class="btn btn-button plan-N-butn halver2 bt"
                type="submit"
              >
                Próximo
              </button>
            </div>
            <div class="copyright">
              <div class="display-flex">
                <img
                  style="
                    width: 20px;
                    vertical-align: middle;
                    border-style: none;
                    margin-right: 5px;
                  "
                  :src="rg.copyright.img"
                  alt=""
                />
                <div>
                  <span
                    ><a
                      :href="rg.copyright.link"
                      class="link_jr"
                      target="_blank"
                      >{{ rg.copyright.title }}</a
                    ><span> todos os direitos reservados</span></span
                  >
                </div>
              </div>
              <p>{{ rg.copyright.copyright }}</p>
            </div>
          </form>

          <!-- FORMULÁRIO DE IDENTIFICAÇÃO PESSOA FISICA -->

          <form
            v-show="PSform.active"
            @submit.prevent="completeForm2()"
            class="formv1 forms"
          >
            <!--Titulo -->
            <div style="display: flex; justify-content: center"></div>
            <h1 class="rec-titleh4 font-medium">{{ rg.tab1.parag }}</h1>

            <!-- ?NAME -->
            <div class="form-group-pessoa">
              <div
                class="display-flex form-group"
                style="align-items: normal; height: 40px"
              >
                <div class="div-icon">
                  <i
                    class="bi bi-card-text"
                    style="font-size: 1rem; color: rgba(46, 46, 46, 0.548)"
                  ></i>
                </div>
                <div
                  class="form-group"
                  style="margin-bottom: 0 !important; width: 100%"
                >
                  <input
                    :class="{ 'warning-name': alert.alertFocus === 'name' }"
                    type="name"
                    id="name"
                    v-model="PSform.name"
                    placeholder=" "
                  />

                  <label
                    :class="{ 'warning-name': alert.alertFocus === 'name' }"
                    class="label-input-text"
                    for="name"
                  >
                    {{
                      savedform.identificationINFO.pessoa !== "fisica"
                        ? "Empresa"
                        : "Nome de usuário"
                    }}
                  </label>
                </div>
              </div>
            </div>
            <div
              class="form-group-pessoa"
              v-if="savedform.identificationINFO.pessoa == 'juridica'"
            >
              <div
                class="display-flex form-group"
                style="align-items: normal; height: 40px"
              >
                <div class="div-icon">
                  <i
                    class="bi bi-card-text"
                    style="font-size: 1rem; color: rgba(46, 46, 46, 0.548)"
                  ></i>
                </div>
                <div
                  class="form-group"
                  style="margin-bottom: 0 !important; width: 100%"
                >
                  <input
                    :class="{ 'warning-social': alert.alertFocus === 'social' }"
                    type="social"
                    id="name"
                    v-model="PSform.social"
                    placeholder=" "
                  />

                  <label
                    :class="{ 'warning-social': alert.alertFocus === 'social' }"
                    class="label-input-text"
                    for="name"
                  >
                    {{
                      savedform.identificationINFO.pessoa !== "juridica"
                        ? "Razão Social"
                        : "Razão Social"
                    }}
                  </label>
                </div>
              </div>
            </div>

            <!-- ?DATE -->
            <div
              class="form-group-pessoa"
              v-if="savedform.identificationINFO.pessoa == 'fisica'"
            >
              <div
                class="display-flex form-group"
                style="align-items: normal; height: 40px"
              >
                <div class="div-icon">
                  <i
                    class="bi bi-calendar2-event"
                    style="font-size: 1rem; color: rgba(46, 46, 46, 0.548)"
                  ></i>
                </div>
                <div
                  v-if="savedform.identificationINFO.pessoa == 'fisica'"
                  class="form-group"
                  style="margin-bottom: 0 !important; width: 100%"
                >
                  <div class="form-group">
                    <input
                      type="date"
                      :class="{
                        'warning-date': alert.alertFocus === 'date',
                        'date-turn-color': PSform.turnDate == true,
                      }"
                      id="date"
                      v-model="PSform.date"
                      placeholder=""
                    />

                    <label
                      :class="{ 'warning-date': alert.alertFocus === 'date' }"
                      class="label-input-text"
                      for="date"
                    >
                      Data de nascimento
                    </label>
                  </div>
                  <div class="label-down"></div>
                </div>
              </div>
            </div>

            <!-- ?NUMBER -->
            <div class="form-group-pessoa">
              <div
                class="display-flex form-group"
                style="align-items: normal; height: 40px"
              >
                <div class="div-icon">
                  <i
                    class="bi bi-telephone"
                    style="font-size: 1rem; color: rgba(46, 46, 46, 0.548)"
                  ></i>
                </div>
                <div
                  class="form-group"
                  style="margin-bottom: 0 !important; width: 100%"
                >
                  <input
                    type="phone"
                    :class="{ 'warning-fone': alert.alertFocus === 'fone' }"
                    id="fone"
                    v-model="PSform.fone"
                    v-maska
                    :data-maska="'(##) ####-#####'"
                    placeholder=" "
                  />

                  <label
                    :class="{ 'warning-fone': alert.alertFocus === 'fone' }"
                    class="label-input-text"
                    for="fone"
                  >
                    Número de telefone.
                  </label>
                </div>
              </div>
            </div>
            <!-- ?E-mail -->
            <div class="form-group-pessoa">
              <div
                class="display-flex form-group"
                style="align-items: normal; height: 40px"
              >
                <div class="div-icon">
                  <i
                    class="bi bi-at"
                    style="font-size: 1rem; color: rgba(46, 46, 46, 0.548)"
                  ></i>
                </div>
                <div
                  class="form-group"
                  style="margin-bottom: 0 !important; width: 100%"
                >
                  <input
                    type="email"
                    :class="{ 'warning-email': alert.alertFocus === 'email' }"
                    id="email"
                    v-model="PSform.email"
                    v-maska
                    :data-maska="''"
                    placeholder=" "
                  />

                  <label
                    :class="{ 'warning-email': alert.alertFocus === 'email' }"
                    class="label-input-text"
                    for="email"
                  >
                    Endereço email
                  </label>
                </div>
              </div>
            </div>

            <!-- Botões -->
            <div class="button-aling">
              <div class="button-flex">
                <div class="display-flex button-box">
                  <button
                    :style="`background-color: ${pl[Palet].destach_areas};`"
                    class="btn btn-button plan-N-butn buton-flex halver2 btn-outline-secondary"
                    type="submit"
                  >
                    Próximo
                  </button>
                </div>
                <div class="display-flex button-box">
                  <button
                    @click="back((backTo = 1))"
                    :style="`background-color: ${pl[Palet].destach_areas};`"
                    class="btn btn-button plan-N-butn buton-flex halver2 btn-outline-secondary"
                    type="button"
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </div>
            <div class="copyright">
              <div class="display-flex">
                <img
                  style="
                    width: 20px;
                    vertical-align: middle;
                    border-style: none;
                    margin-right: 5px;
                  "
                  :src="rg.copyright.img"
                  alt=""
                />
                <div>
                  <span
                    ><a
                      :href="rg.copyright.link"
                      class="link_jr"
                      target="_blank"
                      >{{ rg.copyright.title }}</a
                    ><span> todos os direitos reservados</span></span
                  >
                </div>
              </div>
              <p>{{ rg.copyright.copyright }}</p>
            </div>
          </form>

          <!-- ?VerifyForm -->
          <form
            v-show="verifyForm.active"
            @submit.prevent="confirmVerify()"
            class="formv1 forms"
          >
            <div>
              <!--Titulo -->
              <div style="display: flex; justify-content: center"></div>
              <h1 class="rec-titleh4 font-medium">{{ rg.tab1.parag }}</h1>
            </div>
            <div v-if="pix.loading == true">
              <div class="text-center wait-qrcode">
                <div
                  :style="`color: ${pl[Palet].destach_areas};`"
                  class="spinner-border"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
            <div v-if="pix.loading == false">
              <div class=""></div>
              <div class="plan-verify">
                <div class="prices" ref="prices">
                  <div class="plans detach-off">
                    <div>
                      <table class="table table- table-hover">
                        <tbody>
                          <tr>
                            <td colspan="2" class="table-td">
                              <strong
                                >{{
                                  savedform.identificationINFO.pessoa !==
                                  "fisica"
                                    ? "CNPJ"
                                    : "CPF"
                                }}:</strong
                              >
                            </td>
                            <td colspan="2" class="text-end table-td">
                              {{
                                savedform.identificationINFO.pessoa !== "fisica"
                                  ? formatCNPJ(savedform.identificationINFO.rgi)
                                  : formatCPF(savedform.identificationINFO.rgi)
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" class="table-td">
                              <strong>
                                {{
                                  rgiform.pessoa !== "fisica"
                                    ? "Empresa:"
                                    : "Nome:"
                                }}
                              </strong>
                            </td>
                            <td colspan="2" class="text-end table-td">
                              {{ savedform.identificationINFO.name }}
                            </td>
                          </tr>

                          <tr
                            v-if="
                              savedform.identificationINFO.pessoa == 'fisica'
                            "
                          >
                            <td colspan="2" class="table-td">
                              <strong> Data de Nascimento: </strong>
                            </td>
                            <td colspan="2" class="text-end table-td">
                              {{ savedform.identificationINFO.date }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" class="table-td">
                              <strong> Email: </strong>
                            </td>
                            <td colspan="2" class="text-end table-td">
                              {{ savedform.identificationINFO.email }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" class="table-td">
                              <strong>Telefone:</strong>
                            </td>
                            <td colspan="2" class="text-end table-td">
                              {{ savedform.identificationINFO.fone }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <div style="margin-top: 20px">
                      <div class="plan-title plan-flex">
                        <h4 class="plan-N-title">{{ plan.title }}</h4>
                      </div>

                      <div class="price">
                        <div class="plan-N-price">
                          <h3 class="plan-N-priceH3 price-valor">
                            {{
                              formatCurrency(
                                payment.plan_type !== "MONTHLY"
                                  ? plan.annual_price
                                  : plan.monthly_price
                              )
                            }}
                          </h3>
                          <p
                            class="plan-N-priceP"
                            :class="plan.custom_price ? 'custom_price' : ''"
                          ></p>
                        </div>
                        <p class="plan-N-price_description">
                          <span v-if="payment.plan_type == 'ANNUAL'"
                            >parcela única anual
                          </span>
                          <span v-if="payment.plan_type == 'MONTHLY'"
                            >parcela única mensal
                          </span>
                        </p>
                      </div>
                      <div class="detail">
                        <div>
                          <ul class="no-margin">
                            <li
                              v-for="detail in plan.details"
                              :key="detail.index"
                              class="plan-details"
                            >
                              <hr class="plan-hr" />
                              <div class="plan-N-detail-bicon">
                                <i
                                  style="color: black"
                                  class="bi bi-check-lg check-details"
                                ></i>
                                <span class="aling-pp dtl-parag plan-span">{{
                                  detail
                                }}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr />
                      <div
                        style="
                          height: 60px;
                          margin-left: 15px;
                          display: flex;
                          justify-content: space-between;
                        "
                        class="formdpgm"
                      >
                        <p class="plan-N-priceH3 price-valor aoiwd">
                          Forma de pagamento:
                        </p>
                        <h3
                          class="plan-N-priceH3 price-valor"
                          style="margin-right: 10px"
                        >
                          PIX
                        </h3>
                      </div>
                      <div
                        class="formdpgm"
                        style="
                          margin-left: 15px;
                          display: flex;
                          justify-content: space-between;
                        "
                      >
                        <p class="plan-N-priceH3 price-valor aoiwd">
                          Tipo de plano:
                        </p>
                        <h3
                          class="plan-N-priceH3 price-valor"
                          style="margin-right: 10px"
                        >
                          {{
                            payment.plan_type !== "MONTHLY" ? "ANUAL" : "MENSAL"
                          }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="button-aling">
                <div class="button-flex2">
                  <div class="display-flex button-box">
                    <button
                      :style="`background-color: ${pl[Palet].destach_areas};`"
                      class="btn btn-button plan-N-butn buton-flex halver2 btn-outline-secondary"
                      type="submit"
                    >
                      Próximo
                    </button>
                  </div>
                  <div class="display-flex button-box">
                    <button
                      @click="back((backTo = 3))"
                      :style="`background-color: ${pl[Palet].destach_areas};`"
                      class="btn btn-button plan-N-butn buton-flex2 halver2 btn-outline-secondary"
                      type="button"
                    >
                      Voltar
                    </button>
                  </div>
                </div>
              </div>
              <div class="copyright">
                <div class="display-flex">
                  <img
                    style="
                      width: 20px;
                      vertical-align: middle;
                      border-style: none;
                      margin-right: 5px;
                    "
                    :src="rg.copyright.img"
                    alt=""
                  />
                  <div>
                    <span
                      ><a
                        :href="rg.copyright.link"
                        class="link_jr"
                        target="_blank"
                        >{{ rg.copyright.title }}</a
                      ><span> todos os direitos reservados</span></span
                    >
                  </div>
                </div>
                <p>{{ rg.copyright.copyright }}</p>
              </div>
            </div>
          </form>

          <!-- Formulario de escolha de tipo de pagamento -->
          <form
            @submit.prevent=""
            v-show="chossetypeForm.active"
            class="forms formv1 payment-type"
          >
            <!--Titulo -->
            <div style="display: flex; justify-content: center"></div>
            <h1 class="rec-titleh4 font-medium">{{ rg.tab1.parag }}</h1>
            <div>
              <h5 class="chosse-title chosse-1">Selecione o tipo de plano</h5>
              <div class="form-group-pessoa">
                <div
                  class="display-flex form-group"
                  style="align-items: normal; height: 40px"
                >
                  <div
                    class="form-group"
                    style="margin-bottom: 0 !important; width: 100%"
                  >
                    <select
                      id="select"
                      class="form-select"
                      v-model="payment.plan_type"
                    >
                      <option value="ANNUAL" selected>Anual</option>
                      <option value="MONTHLY">Mensal</option>
                      <option value="" disabled>Anual Parcelado</option>
                    </select>
                  </div>
                  <div></div>
                </div>
              </div>
              <hr />
              <h5 class="chosse-title">Selecione o tipo de pagamento</h5>
              <div
                class="display-flex media-chossetipe"
                style="justify-content: space-around"
              >
                <button
                  class="chosse-button chosse-disabled"
                  type="button"
                  @click.prevent="chosseCard()"
                  disabled
                >
                  <img
                    class="imgpix"
                    src="../components/imagens/Card.png"
                    alt=""
                  />
                  <p>CARTÃO</p>
                </button>
                <button
                  @click.prevent="chossePix"
                  type="button"
                  class="chosse-button"
                >
                  <img
                    class="imgpix"
                    src="../components/imagens/logo-pix-icone-256.png"
                    alt=""
                  />
                  <p>PIX</p>
                </button>
              </div>
              <div
                class="display-flex button-box"
                style="margin-top: -25px !important"
              >
                <button
                  type="button"
                  :style="`background-color: ${pl[Palet].destach_areas};`"
                  class="btn btn-button plan-N-butn halver2 bt"
                  @click="back(2)"
                >
                  Voltar
                </button>
              </div>
              <div class="copyright">
                <div class="display-flex">
                  <img
                    style="
                      width: 20px;
                      vertical-align: middle;
                      border-style: none;
                      margin-right: 5px;
                    "
                    :src="rg.copyright.img"
                    alt=""
                  />
                  <div>
                    <span
                      ><a
                        :href="rg.copyright.link"
                        class="link_jr"
                        target="_blank"
                        >{{ rg.copyright.title }}</a
                      ><span> todos os direitos reservados</span></span
                    >
                  </div>
                </div>
                <p>{{ rg.copyright.copyright }}</p>
              </div>
            </div>
          </form>
          <!-- FORMULARIO DE PIX -->
          <form
            @submit.prevent=""
            v-show="pixForm.active"
            class="forms formv1 payment-type"
          >
            <!--Titulo -->
            <div style="display: flex; justify-content: center"></div>
            <h1 class="rec-titleh4 font-medium">{{ rg.tab1.parag }}</h1>

            <div class="fgp-f-pix-title">
              <div v-if="pix.loading == true">
                <div class="text-center wait-qrcode">
                  <div
                    :style="`color: ${pl[Palet].destach_areas};`"
                    class="spinner-border"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <div v-if="pix.loading == false">
                <div>
                  <div class="awdawid">
                    <button
                      v-for="pix in pix.pix_qrcode"
                      :key="pix.index"
                      type="button"
                      :style="`background-color: ${pl[Palet].destach_areas};`"
                      class="btn btn-button plan-N-butn halver2 bt"
                      @click="copiarTexto(pix.pix_brcode)"
                    >
                      Copiar PIX
                    </button>
                  </div>
                </div>
                <div>
                  <div>
                    <h5 class="chosse-title">QRCODE</h5>
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        margin-bottom: 30px;
                      "
                    >
                      <qr-code
                        class="qrcode"
                        v-for="pix in pix.pix_qrcode"
                        :key="pix.index"
                        :text="pix.pix_brcode"
                      ></qr-code>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="display-flex button-box"
              style="margin-top: -25px !important"
            ></div>
            <div class="copyright">
              <div class="display-flex">
                <img
                  style="
                    width: 20px;
                    vertical-align: middle;
                    border-style: none;
                    margin-right: 5px;
                  "
                  :src="rg.copyright.img"
                  alt=""
                />
                <div>
                  <span
                    ><a
                      :href="rg.copyright.link"
                      class="link_jr"
                      target="_blank"
                      >{{ rg.copyright.title }}</a
                    ><span> todos os direitos reservados</span></span
                  >
                </div>
              </div>
              <p>{{ rg.copyright.copyright }}</p>
            </div>
          </form>

          <!-- FORMULÁRIO DE REGISTRO DE CARTÃO -->

          <form
            v-show="CardForm.active"
            @submit.prevent="completeForm3()"
            class="forms formv1"
          >
            <!--Titulo -->
            <div style="display: flex; justify-content: center"></div>
            <h1 class="rec-titleh4 font-medium">{{ rg.tab1.parag }}</h1>

            <!-- ?cardNumber -->
            <div class="form-group-pessoa">
              <div
                class="display-flex form-group"
                style="align-items: normal; height: 40px"
              >
                <div class="div-icon">
                  <i
                    class="bi bi-credit-card"
                    style="font-size: 1rem; color: rgba(46, 46, 46, 0.548)"
                  ></i>
                </div>
                <div
                  class="form-group"
                  style="margin-bottom: 0 !important; width: 100%"
                >
                  <input
                    :class="{
                      'warning-cardNumber': alert.alertFocus === 'cardNumber',
                    }"
                    type="cardNumber"
                    v-maska
                    :data-maska="'####-####-####-####'"
                    id="cardNumber"
                    v-model="CardForm.cardNumber"
                    placeholder=" "
                  />

                  <label
                    :class="{
                      'warning-cardNumber': alert.alertFocus === 'cardNumber',
                    }"
                    class="label-input-text"
                    for="cardNumber"
                  >
                    {{
                      savedform.identificationINFO.pessoa !== "fisica"
                        ? "Número do cartão"
                        : "Número do cartão"
                    }}
                  </label>
                </div>
              </div>
            </div>

            <div class="flex-EDCVC">
              <div class="flex-alings">
                <!-- expirationDate & cvc -->
                <div class="form-group-card">
                  <div
                    class="display-flex form-group"
                    style="align-items: normal; height: 40px"
                  >
                    <div class="div-icon">
                      <i
                        class="bi bi-person"
                        style="font-size: 1rem; color: rgba(46, 46, 46, 0.548)"
                      ></i>
                    </div>
                    <div
                      class="form-group"
                      style="margin-bottom: 0 !important; width: 100%"
                    >
                      <input
                        :class="{
                          'warning-expirationDate':
                            alert.alertFocus === 'expirationDate',
                        }"
                        type="expirationDate"
                        id="expirationDate"
                        v-maska
                        :data-maska="'##/##'"
                        v-model="CardForm.expirationDate"
                        placeholder=" "
                      />
                      <label
                        :class="{
                          'warning-expirationDate':
                            alert.alertFocus === 'expirationDate',
                        }"
                        class="label-input-text"
                        for="expirationDate"
                      >
                        {{ "Validade" }}
                      </label>
                    </div>
                  </div>
                </div>

                <!-- ?cvc -->
                <div class="form-group-card">
                  <div
                    class="display-flex form-group"
                    style="align-items: normal; height: 40px"
                  >
                    <div class="div-icon">
                      <i
                        class="bi bi-person"
                        style="font-size: 1rem; color: rgba(46, 46, 46, 0.548)"
                      ></i>
                    </div>
                    <div
                      class="form-group"
                      style="margin-bottom: 0 !important; width: 100%"
                    >
                      <input
                        :class="{ 'warning-cvc': alert.alertFocus === 'cvc' }"
                        type="cvc"
                        id="cvc"
                        v-maska
                        :data-maska="'###'"
                        v-model="CardForm.cvc"
                        placeholder=" "
                      />

                      <label
                        :class="{ 'warning-cvc': alert.alertFocus === 'cvc' }"
                        class="label-input-text"
                        for="cvc"
                      >
                        {{ "CVC" }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group-pessoa">
              <div
                class="display-flex form-group"
                style="align-items: normal; height: 40px"
              >
                <div class="div-icon">
                  <i
                    class="bi bi-person"
                    style="font-size: 1rem; color: rgba(46, 46, 46, 0.548)"
                  ></i>
                </div>
                <div
                  class="form-group"
                  style="margin-bottom: 0 !important; width: 100%"
                >
                  <input
                    :class="{ 'warning-rgi': alert.alertFocus === 'rgi' }"
                    v-maska
                    :data-maska="'###.###.###-##'"
                    type="pessoa"
                    id="pessoa-2"
                    v-model="CardForm.cardRGI"
                    placeholder=" "
                  />

                  <label
                    :class="{ 'warning-rgi': alert.alertFocus === 'rgi' }"
                    class="label-input-text"
                    for="pessoa"
                    >{{ "CPF do titular" }}
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group-pessoa">
              <div
                class="display-flex form-group"
                style="align-items: normal; height: 40px"
              >
                <div class="div-icon">
                  <i
                    class="bi bi-fonts"
                    style="font-size: 1rem; color: rgba(46, 46, 46, 0.548)"
                  ></i>
                </div>
                <div
                  class="form-group"
                  style="margin-bottom: 0 !important; width: 100%"
                >
                  <input
                    :class="{
                      'warning-name-rgi': alert.alertFocus === 'name-rgi',
                    }"
                    type="pessoa"
                    id="pessoa-2"
                    v-model="CardForm.cardName"
                    placeholder=" "
                  />

                  <label
                    :class="{
                      'warning-name-rgi': alert.alertFocus === 'name-rgi',
                    }"
                    class="label-input-text"
                    for="pessoa"
                    >{{ "Nome conforme cartão" }}
                  </label>
                </div>
              </div>
            </div>

            <!-- Botões -->
            <div class="button-aling">
              <div class="button-flex">
                <div class="display-flex button-box">
                  <button
                    :style="`background-color: ${pl[Palet].destach_areas};`"
                    class="btn btn-button plan-N-butn buton-flex halver2 btn-outline-secondary"
                    type="submit"
                  >
                    Próximo
                  </button>
                </div>
                <div class="display-flex button-box">
                  <button
                    @click="back((backTo = 3))"
                    :style="`background-color: ${pl[Palet].destach_areas};`"
                    class="btn btn-button plan-N-butn buton-flex halver2 btn-outline-secondary"
                    type="button"
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </div>

            <div class="copyright">
              <div class="display-flex">
                <img
                  style="
                    width: 20px;
                    vertical-align: middle;
                    border-style: none;
                    margin-right: 5px;
                  "
                  :src="rg.copyright.img"
                  alt=""
                />
                <div>
                  <span
                    ><a
                      :href="rg.copyright.link"
                      class="link_jr"
                      target="_blank"
                      >{{ rg.copyright.title }}</a
                    ><span> todos os direitos reservados</span></span
                  >
                </div>
              </div>
              <p>{{ rg.copyright.copyright }}</p>
            </div>
          </form>
          <form class="formv1 forms" v-show="successful.active">
            <!--Titulo -->

            <div class="success-icon-dad">
              <img
                src="./imagens/success-icon.png"
                class="success-icon"
                alt=""
              />
            </div>
            <div>
              <h4 class="medium-txtAlin-black">Compra Realizada com Sucesso</h4>
              <p class="small-txtAlin-gray">
                Sua compra foi realizada com sucesso, entraremos em contato via
                e-mail ou whatsapp.
              </p>
              <div class="CCD-dad">
                <div class="CodigoCompra-dad">
                  <strong>Codigo de compra:</strong>
                  <h6 class="CodigoCompra">{{ pix.PaymentId }}</h6>
                </div>
              </div>
            </div>

            <div class="copyright">
              <div class="display-flex">
                <img
                  style="
                    width: 20px;
                    vertical-align: middle;
                    border-style: none;
                    margin-right: 5px;
                  "
                  :src="rg.copyright.img"
                  alt=""
                />
                <div>
                  <span
                    ><a
                      :href="rg.copyright.link"
                      class="link_jr"
                      target="_blank"
                      >{{ rg.copyright.title }}</a
                    ><span> todos os direitos reservados</span></span
                  >
                </div>
              </div>
              <p>{{ rg.copyright.copyright }}</p>
            </div>
          </form>
          <br />
          <div v-show="alert.alertBolean">
            <div :class="alert.alertClass" role="alert" class="alert">
              <div class="">
                <p>{{ alert.alertMessage }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>





<script>
import VueQRCodeComponent from "vue-qrcode-component";
import { defineComponent } from "vue";
import "bootstrap-icons/font/bootstrap-icons.css";
import { VueMaskDirective } from "v-mask";
import customer from "../services/products.js";
import payment from "../services/products.js";
import Swal from "sweetalert2";
import check from "../services/products.js";
import { data } from "../App.vue";

export default defineComponent({
  name: "ContractPage",
  components: {
    "qr-code": VueQRCodeComponent,
  },
  // ?Data
  data() {
    return {
      customer: {
        name: "",
        document: "",
        birthday: "",
        phone: "",
        email: "",
      },
      Palet: [],
      dev: false,
      buyLink: "",
      productId: "",
      db3: data,
      pl: [],
      db: [],
      rg: [],
      successful: {
        active: false,
      },
      CardForm: {
        active: false,
        cardNumber: "",
        cvc: "",
        expirationDate: "",
        cardRGI: "",
        cardName: "",
        validCardRGI: false,
        cardPeople: "fisica",
      },
      chossetypeForm: {
        active: false,
        loading: false,
      },
      pixForm: {
        active: false,
      },
      verifyForm: {
        active: false,
      },
      PSform: {
        active: false,
        fisica: "",
        idade: "",
        social: "",
        name: "",
        date: "",
        fone: "",
        email: "",
        turnDate: false,
      },
      rgiform: {
        active: true,
        rgi: "",
        pessoa: "fisica",
        validCPF: false,
        validrgi: false,
        validCNPJ: false,
      },
      savedform: {
        cardINFO: {
          // CPF Titular
          titularcpf: "",
          // Informações de cartão
          cardNumber: "",
          expirationDate: "",
          cvc: "",
          cardName: "",
        },
        identificationINFO: {
          // RGI e tipo de pessoa
          pessoa: "",
          rgi: "",
          // Nome, data de nascimento(fisica), telefone e email
          name: "",
          date: null,
          fone: "",
          social: "",
          email: "",
        },
      },
      alert: {
        alertFocus: "focus",
        alertFocusBirth: "focus",
        alertBolean: false,
        alertMessage: "Alert",
        alertClass: "alert alert-warning d-flex align-items-center",
      },
      selectedPlanId: this.productId,
      save: {
        name: "",
        birthday: null,
        document: "",
        phone: "",
        email: "",
      },
      customerid: "",
      payment: {
        customer_id: "",
        product_plan_id: "",
        integration_id: "String",
        payment_type: "UNDEFINED",
        plan_type: "ANNUAL",
      },
      pix: {
        key: [],
        pix_qrcode: [],
        PaymentInstallmentId: null,
        loading: false,
        waiting: false,
        intervalId: null,
        response: [],
        checkResponse: [],
        price: "",
        checkTimer: null,
        PaymentId: "",
      },
      plan: {},
      details: [],
      planprice: "",
    };
  },
  mounted() {
    this.findById(this.productId);
  },
  created() {
    this.db = this.db3.contracts;
    this.Palet = this.db3.color_palet.Select;
    this.pl = this.db3.color_palet;
    this.rg = this.db3.register;
    // pegar id
    if (this.$route.params.id) {
      this.productId = this.$route.params.id.toString();
      const item = this.findById(this.productId);
      if (item) {
        this.plan = item;
        if (!Array.isArray(this.plan.details)) {
          this.plan.details = JSON.parse(this.plan.details);
        }
      } else {
        Swal.fire({
          title: "Erro ao carregar dados do plano",
          text: "houve um erro ao tentar carregar os dados de plano",
          icon: "error",
          confirmButtonText: "Fechar",
          confirmButtonColor: "#d33",
        });
      }
    }
  },
  methods: {
    directives: {
      mask: VueMaskDirective,
    },
    formatCPF(value) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    formatCNPJ(value) {
      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    },
    findById(thisProductId) {
      return this.db.find((item) => item.product_plan_id == thisProductId);
    },
    toPlan() {
      if (this.selectedPlanId) {
        this.$router.push(`/contract/${this.selectedPlanId}`);
        this.productId = this.selectedPlanId;
        this.verifyRoute();
      }
    },
    formatCurrency(value) {
      if (!value) return "R$ 0,00";
      const formattedValue = (value / 1).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formattedValue;
    },
    copiarTexto(texto) {
      console.log(texto);
      const tempInput = document.createElement("input");
      tempInput.value = texto;
      document.body.appendChild(tempInput);

      tempInput.select();
      tempInput.setSelectionRange(0, 99999);
      document.execCommand("copy");

      document.body.removeChild(tempInput);
      Swal.fire({
        title: "Copiado com sucesso",
        text: "Você copiou com sucesso a chave pix",
        icon: "success",
        confirmButtonText: "Fechar",
        confirmButtonColor: "#d33",
      });
    },
    chosseCard() {
      this.rgiform.active = false;
      this.PSform.active = false;
      this.CardForm.active = false;
      this.verifyForm.active = false;
      this.chossetypeForm.active = false;
      this.pixForm.active = false;
      this.CardForm.active = false;
      this.successful.active = true;
      this.CardForm.active = true;
    },
    verifyRoute() {
      this.findById(this.productId);
      const item = this.findById(this.productId);
      if (item) {
        this.plan = item;
      }
    },
    back(to) {
      this.rgiform.active = false;
      this.PSform.active = false;
      this.CardForm.active = false;
      this.pixForm.active = false;

      this.verifyForm.active = false;
      this.chossetypeForm.active = false;

      if (to == 1) {
        this.rgiform.active = true;
      }
      if (to == 2) {
        this.PSform.active = true;
      }
      if (to == 3) {
        this.chossetypeForm.active = true;
      }
    },

    // Salvar na API
    getPaymentInstallmentId() {
      this.PaymentInstallmentId = this.pix.response[0].payment_installment_id;
    },
    chossePix() {
      this.rgiform.active = false;
      this.PSform.active = false;
      this.CardForm.active = false;
      this.chossetypeForm.active = false;
      this.pixForm.active = false;
      this.details = this.plan.details;
      this.verifyForm.active = true;
    },

    async confirmVerify() {
      this.pix.loading = true;

      // Preenchendo os dados do cliente
      this.save.birthday = this.savedform.identificationINFO.date;
      this.save.name = this.savedform.identificationINFO.name;
      this.save.document = this.savedform.identificationINFO.rgi;
      this.save.phone = this.savedform.identificationINFO.fone;
      this.save.email = this.savedform.identificationINFO.email;

      // Configurando o tipo de pagamento como PIX e informações relacionadas
      this.payment.payment_type = "PIX";
      this.payment.product_plan_id = this.productId;
      this.payment.customer_id = this.customerid;

      try {
        // Salvando os dados do cliente
        const customerResponse = await customer.save(this.save);
        this.payment.customer_id = customerResponse.data.customer_id;

        // Aguardando um curto intervalo antes de realizar o pagamento
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Realizando o pagamento PIX
        const paymentResponse = await payment.pix(this.payment);

        this.verifyForm.active = false;
        this.pixForm.active = true;
        this.pix.loading = false;

        // Atualizando os estados e informações relacionadas ao pagamento
        this.pix.PaymentId = paymentResponse.data.payment_id;
        this.pix.key = paymentResponse.data.installments;
        this.pix.pix_qrcode = paymentResponse.data.installments;
        this.pix.response = paymentResponse.data.installments;

        // Executando outras ações após o pagamento
        this.getPaymentInstallmentId();
        this.startChecking();
      } catch (error) {
        // Lidando com erros ao salvar cliente ou efetuar o pagamento
        let errorMessage = "Erro desconhecido";
        if (error.response && error.response.data && error.response.data[0]) {
          errorMessage = error.response.data[0].message;
        }
        Swal.fire({
          text: errorMessage,
          title: "Erro ao processar pagamento",
          icon: "error",
          confirmButtonText: "Fechar",
          confirmButtonColor: "#d33",
        });
        console.log(error);

        // Revertendo o estado em caso de erro
        this.verifyForm.active = true;
        this.pixForm.active = false;
        this.pix.loading = false;
      }
    },
    stopChecking() {
      if (this.pix.checkTimer != null) {
        clearTimeout(this.pix.checkTimer);
        this.pix.checkTimer = null;
      }
    },
    startChecking() {
      this.stopChecking();
      this.pix.checkTimer = setTimeout(() => {
        this.checkPayment();
      }, 5000);
    },
    checkPayment() {
      check
        .pixStatus(this.pix.response[0].payment_installment_id)
        .then((Response) => {
          this.pix.price = Response.data.value;
          var paymentStatus = Response.data.status;
          if (paymentStatus === "RECEIVED") {
            this.stopChecking();
            this.pixForm.active = false;
            this.successful.active = true;
          } else {
            this.startChecking();
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Erro ao realizar pagamento!",
            text: error.response.data[0].message,
            icon: "error",
            confirmButtonText: "Fechar",
            confirmButtonColor: "#d33",
          });
        });
    },
    go(to) {
      this.rgiform.active = false;
      this.PSform.active = false;
      this.chossetypeForm.active = false;
      this.pixForm.active = false;

      this.CardForm.active = false;
      this.verifyForm.active = false;

      if (to == 2 && this.rgiform.pessoa == "fisica") {
        this.validCPF == false;
        this.validCPF();
        if (this.rgiform.validCPF == true) {
          this.savedform.identificationINFO.rgi = this.rgiform.rgi;
          this.savedform.identificationINFO.pessoa = this.rgiform.pessoa;
          this.PSform.active = true;
        } else {
          this.alertError();
          this.rgiform.active = true;
        }
      }
      if (to == 2 && this.rgiform.pessoa == "juridica") {
        this.validCNPJ == false;
        this.validCNPJ();
        if (this.rgiform.validCNPJ == true) {
          this.savedform.identificationINFO.rgi = this.rgiform.rgi.replace(
            /[^\d]+/g,
            ""
          );
          this.savedform.identificationINFO.pessoa = this.rgiform.pessoa;
          this.PSform.active = true;
        } else {
          this.alertError();
          this.rgiform.active = true;
        }
      }
    },
    validateOK() {
      if (this.rgiform.pessoa === "fisica") {
        this.savedform.identificationINFO.rgi = this.rgiform.rgi;
        this.savedform.identificationINFO.pessoa = this.rgiform.pessoa;
        const PSform = () => {
          this.rgiform.active = false;
          this.PSform.active = true;
        };
        setTimeout(PSform, 80);
      }
      if (this.rgiform.pessoa === "juridica") {
        this.savedform.identificationINFO.rgi = this.rgiform.rgi.replace(
          /[^\d]+/g,
          ""
        );
        this.savedform.identificationINFO.pessoa = this.rgiform.pessoa;
        const PSform = () => {
          this.rgiform.active = false;
          this.PSform.active = true;
        };

        setTimeout(PSform, 80);
      }
      return;
    },
    completeForm() {
      // Verificar CPF:
      if (this.rgiform.pessoa == "fisica") {
        this.validCPF();
      }
      // Verificar CNPJ:
      if (this.rgiform.pessoa == "juridica") {
        this.validCNPJ();
      }
      if (this.rgiform.validCPF || this.rgiform.validCNPJ == true) {
        this.validateOK();
        setTimeout(this.clearAlert, 7000);
      } else {
        this.alertError();
      }
    },
    verificarIdade() {
      const hoje = new Date();
      const nascimento = new Date(this.PSform.date);

      const diffMilissegundos = hoje - nascimento;
      const diffDias = Math.floor(diffMilissegundos / (1000 * 60 * 60 * 24));

      this.PSform.idade = diffDias;
    },
    completeForm2() {
      this.verificarIdade();
      if (
        (this.PSform.date == "" &&
          this.savedform.identificationINFO.pessoa == "fisica") ||
        (this.PSform.idade < 6565 &&
          this.savedform.identificationINFO.pessoa == "fisica") ||
        this.PSform.name == "" ||
        this.PSform.fone == "" ||
        (this.PSform.social == "" &&
          this.savedform.identificationINFO.pessoa == "juridica") ||
        this.PSform.email == "" ||
        this.PSform.fone2 == this.PSform.fone
      ) {
        this.alertError2();
      } else {
        this.psformSucess();
      }
    },
    psformSucess() {
      if (this.savedform.identificationINFO.pessoa == "juridica") {
        this.savedform.identificationINFO.date = null;
        this.savedform.identificationINFO.name = this.PSform.social;
      } else {
        this.savedform.identificationINFO.date = this.PSform.date;
        this.savedform.identificationINFO.name = this.PSform.name;
      }
      this.savedform.identificationINFO.social = this.PSform.social;
      this.savedform.identificationINFO.fone = this.PSform.fone;
      this.savedform.identificationINFO.email = this.PSform.email;

      this.PSform.active = false;
      this.rgiform.active = false;
      this.PSform.active = false;
      this.CardForm.active = false;
      this.verifyForm.active = false;

      this.pixForm.active = false;
      console.log(this.plan.monthly_price);
      if (this.plan.monthly_price == 0 && this.plan.annual_price == 0) {
        this.successful.active = true;
      } else {
        this.chossetypeForm.active = true;
      }
    },

    completeForm3() {
      this.validTitularCPF();

      if (
        this.CardForm.cardName == "" ||
        this.CardForm.cardRGI == "" ||
        this.CardForm.validCardRGI == false ||
        this.CardForm.cardNumber == "" ||
        this.CardForm.cardNumber.length < 9 ||
        this.CardForm.expirationDate == "" ||
        this.CardForm.cvc == "" ||
        this.CardForm.cvc.length < 3 ||
        this.CardForm.expirationDate.length < 5
      ) {
        this.alertError3();
        console.error("error");
      } else {
        this.savedform.cardINFO.cvc = this.CardForm.cvc;
        this.savedform.cardINFO.expirationDate = this.CardForm.expirationDate;
        this.savedform.cardINFO.cardNumber = this.CardForm.cardNumber;
        this.savedform.cardINFO.titularcpf = this.CardForm.cardRGI;
        this.savedform.cardINFO.cardName = this.CardForm.cardName;

        this.PSform.active = false;
        this.CardForm.active = false;
        this.chossetypeForm.active = true;
      }
    },

    alertError() {
      this.alert.alertMessage = `${
        this.rgiform.pessoa !== "fisica" ? "CNPJ" : "CPF"
      }, invalido ou não preenchido`;
      this.alert.alertClass = "alert alert-danger d-flex align-items-center";
      this.alert.alertBolean = true;
      this.alert.alertFocus = "cpf";
      setTimeout(this.clearAlert, 4000);
    },

    // Erro de alerta por falta de dados de INDENTIFICAÇÃO

    alertError2() {
      if (this.PSform.email == "") {
        this.alert.alertFocus = "email";
        this.alert.alertMessage = `Endereço de email invalido ou não preenchido.`;
        this.alert.alertClass = "alert alert-danger d-flex align-items-center";
        this.alert.alertBolean = true;
        setTimeout(this.clearAlert, 4000);
      }

      if (this.PSform.fone == "") {
        this.alert.alertFocus = "fone";
        this.alert.alertMessage = `Número de telefone invalido ou não preenchido.`;
        this.alert.alertClass = `alert ${
          this.PSform.fone2 == this.PSform.fone
            ? "alert-danger"
            : "alert-danger"
        } d-flex align-items-center`;
        this.alert.alertBolean = true;
        setTimeout(this.clearAlert, 4000);
      }
      if (
        (this.PSform.idade < 6565 &&
          this.savedform.identificationINFO.pessoa == "fisica") ||
        (this.PSform.date == "" &&
          this.savedform.identificationINFO.pessoa == "fisica")
      ) {
        this.alert.alertFocus = "date";
        this.alert.alertMessage = `Data de nascimento invalida ou não preenchida.`;
        this.alert.alertClass = "alert alert-danger d-flex align-items-center";
        this.alert.alertBolean = true;
        setTimeout(this.clearAlert, 4000);
      }
      if (
        this.PSform.social == "" &&
        this.savedform.identificationINFO.pessoa == "juridica"
      ) {
        this.alert.alertFocus = "social";
        this.alert.alertMessage = `Endereço de Razão Social invalido ou não preenchido.`;
        this.alert.alertClass = "alert alert-danger d-flex align-items-center";
        this.alert.alertBolean = true;
        setTimeout(this.clearAlert, 4000);
      }
      if (this.PSform.name == "") {
        this.alert.alertFocus = "name";
        this.alert.alertMessage = `${
          this.rgiform.pessoa !== "fisica" ? "Nome" : "Nome"
        } invalido ou não preenchido.`;
        this.alert.alertClass = "alert alert-danger d-flex align-items-center";
        this.alert.alertBolean = true;
        setTimeout(this.clearAlert, 4000);
      }
    },

    alertError3() {
      if (this.CardForm.cardName == "") {
        this.alert.alertFocus = "name-rgi";
        this.alert.alertMessage = `Nome conforme cartão invalido ou não preenchido.`;
        this.alert.alertClass = `alert alert-danger d-flex align-items-center`;
        this.alert.alertBolean = true;
        setTimeout(this.clearAlert, 4000);
      }

      if (this.CardForm.cardRGI == "" || this.CardForm.validCardRGI == false) {
        this.alert.alertFocus = "rgi";
        this.alert.alertMessage = `CPF do titular invalido ou não preenchido.`;
        this.alert.alertClass = `alert alert-danger d-flex align-items-center`;
        this.alert.alertBolean = true;
        setTimeout(this.clearAlert, 4000);
      }

      if (this.CardForm.cvc == "" || this.CardForm.cvc.length < 3) {
        this.alert.alertFocus = "cvc";
        this.alert.alertMessage = `cvc invalido ou não preenchido.`;
        this.alert.alertClass = "alert alert-danger d-flex align-items-center";
        this.alert.alertBolean = true;
        setTimeout(this.clearAlert, 4000);
      }

      if (
        this.CardForm.expirationDate == "" ||
        this.CardForm.expirationDate.length < 5
      ) {
        this.alert.alertFocus = "expirationDate";
        this.alert.alertMessage = `Validade invalida ou não preenchida.`;
        this.alert.alertClass = "alert alert-danger d-flex align-items-center";
        this.alert.alertBolean = true;
        setTimeout(this.clearAlert, 4000);
      }
      if (
        this.CardForm.cardNumber == "" ||
        this.CardForm.cardNumber.length < 9
      ) {
        this.alert.alertFocus = "cardNumber";
        this.alert.alertMessage = `Número do cartão invalido ou não preenchido.`;
        this.alert.alertClass = `alert alert-danger d-flex align-items-center`;
        this.alert.alertBolean = true;
        setTimeout(this.clearAlert, 4000);
      }
    },

    clearAlert() {
      this.alert.alertMessage = "";
      this.alert.alertClass = "";
      this.alert.alertBolean = false;
      this.alert.alertFocus = "";
    },

    validCNPJ() {
      let cnpj = this.rgiform.rgi.replace(/[^\d]+/g, "");

      if (cnpj.length !== 14) {
        this.rgiform.validCNPJ = false;
        return;
      }

      // Verificando se todos os números são iguais
      if (/^(\d)\1+$/.test(cnpj)) {
        this.rgiform.validCNPJ = false;
        return;
      }

      // Validação do primeiro dígito verificador
      let sum = 0;
      let factor = 5;
      for (let i = 0; i < 12; i++) {
        sum += parseInt(cnpj.charAt(i)) * factor;
        factor = factor === 2 ? 9 : factor - 1;
      }
      let remainder = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (remainder !== parseInt(cnpj.charAt(12))) {
        this.rgiform.validCNPJ = false;
        return;
      }

      // Validação do segundo dígito verificador
      sum = 0;
      factor = 6;
      for (let i = 0; i < 13; i++) {
        sum += parseInt(cnpj.charAt(i)) * factor;
        factor = factor === 2 ? 9 : factor - 1;
      }
      remainder = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (remainder !== parseInt(cnpj.charAt(13))) {
        this.rgiform.validCNPJ = false;
        return;
      }

      // Se chegou até aqui, o CNPJ é válido
      this.rgiform.validCNPJ = true;
    },

    validTitularCPF() {
      let cpf = this.CardForm.cardRGI.replace(/[^\d]+/g, "");
      if (cpf.length !== 11) {
        this.CardForm.validCardRGI = false;
        return;
      }
      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let remainder = 11 - (sum % 11);
      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }
      if (remainder !== parseInt(cpf.charAt(9))) {
        this.CardForm.validCardRGI = false;
        return;
      }
      sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
      }
      remainder = 11 - (sum % 11);
      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }
      if (remainder !== parseInt(cpf.charAt(10))) {
        this.CardForm.validCardRGI = false;
        return;
      }
      this.CardForm.validCardRGI = true;
    },

    validCPF() {
      let cpf = this.rgiform.rgi.replace(/[^\d]+/g, "");
      if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        this.rgiform.validCPF = false;
        return;
      }

      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let remainder = 11 - (sum % 11);
      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }
      if (remainder !== parseInt(cpf.charAt(9))) {
        this.rgiform.validCPF = false;
        return;
      }

      sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
      }
      remainder = 11 - (sum % 11);
      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }
      if (remainder !== parseInt(cpf.charAt(10))) {
        this.rgiform.validCPF = false;
        return;
      }

      this.rgiform.validCPF = true;
    },
  },

  watch: {
    "rgiform.rgi": {
      handler(value) {
        const rgiNumbersOnly = value.replace(/[.-]/g, "");
        if (rgiNumbersOnly.length > 11) {
          this.rgiform.pessoa = "juridica";
        } else {
          this.rgiform.pessoa = "fisica";
        }
      },
      immediate: true,
    },
    "PSform.date": {
      handler(value) {
        const dateOnli = value.replace(/[/.-qawsedrftgyhujikolpçzxcvbnm]/g, "");
        if (dateOnli.length > 0) {
          this.PSform.turnDate = true;
        } else {
          this.PSform.turnDate = false;
        }
      },
      immediate: true,
    },
  },
});
</script>






<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.mysign-img {
  width: 100%;
  height: 100%;
}

.form-check-input[type="radio"] {
  border-radius: 29%;
}

.form-group-pessoa {
  width: 75%;
  margin: 0 auto 0 auto;
}

.flex-drop-rgi {
  display: flex;
}

.form-group-pessoa-flex {
  width: 36.5%;
  margin-left: 1%;
  margin-right: 1%;
}

.form-group-pessoa-select {
  display: flex;
}

.form-group-pessoa-input {
  width: 100%;
}

.text-aling {
  text-align: center;
}

.button-box {
  width: 100%;
  margin: 0 auto 0 auto;
  margin-top: -40px;
}

.alert {
  height: 50px;
  margin: 0 auto 0 auto;
  margin-bottom: 50px !important;
  text-align: center !important;
  padding-top: 30px;
}

.required-field-indicator {
  color: red;
  margin-left: 5px;
}

#date {
  color: #999;
}

.form-check-input:checked {
  background-color: #4b4bc5;
}

.label-radios {
  text-align: center;
  margin-bottom: 26px;
  margin-top: 16px;
}

.label-down {
  margin-top: 25px;
  margin-bottom: 20px;
  margin-left: 10px;
  color: #929292;
}

.form-group label {
  position: absolute;
  top: 10px;
  left: 0;
  padding: 0 0.5rem;
  color: #999;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.form-group input:focus ~ .label-input-text,
.form-group input:not(:placeholder-shown) ~ label {
  transform: translateY(-1.5rem) scale(0.8);
  font-size: 1rem;
  color: #333333aa;
  background-color: #ffffff;
}

.container {
  margin: 0 auto 0 auto;
  padding: 20px;
  padding-inline: 20px;
  padding-bottom: -10px;
  max-width: 500px !important;
}

.containerM {
  margin: 0 auto 0 auto;
  padding: 20px;
  padding-inline: 20px;
  padding-bottom: -10px;
  max-width: 600px !important;
}

.logo {
  display: block;
  max-width: 200px;
  margin-left: 3px;
  margin: -5px;
  margin-top: -2px;
  margin-bottom: 30px;
}

.forms {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px !important;
  height: auto;
  width: 100%;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.103);
  transition: all 0.4s;
}

.forms:hover {
  padding: 20px;
  border-radius: 5px !important;
  height: auto;
  width: 100%;
  box-shadow: 4px 4px 20px 3px rgba(0, 0, 0, 0.131);
}

.pix-title {
  font-size: 13px;
}
.pix-div-title {
  justify-content: center;
  display: flex;
}
.dad-pix-key {
  text-align: center;
  border: 1px solid #777777;
  margin: 1px;
  padding: 3px;
  margin-bottom: 40px;
}
.button-paste {
  border: transparent solid 0px;
  background: none;
}

.pix-icon-paste {
  height: 130%;
  width: auto;
  margin-right: -5px;
}

.wait-qrcode {
  margin-top: 50px;
  margin-bottom: 50px;
}
.media-chossetipe {
  padding-inline: 44px;
}
@media (max-width: 430px) {
  .pix-title {
    font-size: 11px !important;
  }
  .fgp-f-pix-title {
    width: 100% !important;
  }
  .dad-pix-key {
    border: none !important;
    padding: none !important;
    margin: none !important;
  }
  .media-chossetipe {
    padding-inline: 0px !important;
  }
  @media (max-width: 350px) {
    .table-td {
      padding: none !important;
      font-size: 12px;
    }
    .plans {
      border: none !important;
      padding: none !important;
      margin: none !important;
    }
    .formdpgm {
      justify-content: space-evenly !important;
      align-items: center !important;
      height: auto !important;
    }
  }
  @media (max-width: 300px) {
    .dad-pix-key {
      border: none !important;
    }
    .qrcode {
      border: none !important;
    }
  }
}

.fgp-f-pix-title {
  width: 75%;
  margin: 0 auto 0 auto;
}

.qrcode {
  display: flex;
  justify-content: center;
  border: #000000 1px solid;
  padding: 12px;
}

#select,
#rgi,
input[type="name"],
input[type="social"],
input[type="pessoa"],
input[type="phone"],
input[type="date"],
input[type="email"],
input[type="cpf"],
input[type="cardNumber"],
input[type="cvc"],
input[type="expirationDate"],
input[type="text"] {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 2px;
  transition: all 0.3s ease-out;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0 !important;
}
#select:focus,
input[type="name"]:focus,
input[type="cnpj"]:focus,
input[type="email"]:focus,
input[type="pessoa"]:focus,
input[type="phone"]:focus,
input[type="cardNumber"]:focus,
input[type="cvc"]:focus,
input[type="expirationDate"]:focus,
input[type="date"]:focus,
input[type="pessoa"]:focus,
input[type="text"]:focus {
  outline: none;
  border-color: #252a32;
  border: 1.3px solid #252a32;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  -webkit-text-fill-color: #333 !important;
}

label.warning,
label.warning-cpf,
label.warning-name,
label.warning-social,
label.warning-date,
label.warning-name-rgi,
label.warning-expirationDate,
label.warning-cvc,
label.warning-rgi,
label.warning-cardNumber,
label.warning-email,
label.warning-fone {
  color: #b01111 !important;
  background-color: rgb(255, 238, 238) !important;
}

input.warning,
input.warning-cpf,
input.warning-name,
input.warning-social,
input.warning-cvc,
input.warning-cardNumber,
input.warning-rgi,
input.warning-name-rgi,
input.warning-expirationDate,
input.warning-email,
input.warning-date,
input.warning-fone {
  outline: none;
  border-color: #b01111 !important;
  border: 1px solid #b01111 !important;
  color: #b01111 !important;
  background-color: rgb(255, 238, 238) !important;
}

input:focus + .label-input-text,
input:not(:placeholder-shown) + .label-input-text {
  transform: translateY(-1.5em);
  font-size: 0.8em;
  color: #3c8dbc;
  transition: all 0.3s ease-out;
}

.label-input-text {
  display: block;
  position: relative;
  top: -20px;
  left: 0;
  font-size: 1em;
  color: #999;
  transition: all 0.3s ease-out;
}

.date-turn-color {
  color: #000000 !important;
}

button[type="submit"]:hover {
  background-color: #337ab7;
}

.margin-top {
  margin-top: 3rem;
}
.awdawid {
  display: flex;
  justify-content: center;
  margin-top: -30px;
}

.OAJDNIOOI {
  margin-bottom: 0px !important;
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 0px !important;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 2px;
  transition: all 0.3s ease-out;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0 !important;
}
/* RECURSOS: */

.rec-display-flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.rec-box {
  margin-top: 20px;
  background-image: url("imagens/Untitled-1.png");
  background-repeat: no-repeat;
  background-position: top center;
}

.rec-parag {
  font-family: Quicksand;
  font-size: 1.3rem;
}

.rec-title {
  font-family: Roboto-Regular;
  font-size: 1.7rem;
  text-align: center;
  letter-spacing: 0px;
  color: #092654b5;
}

.rec-titleh4 {
  font-family: Poppins-Medium;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 10px;
  font-weight: 200;
  letter-spacing: -0.3px;
  color: #313131b5;
  margin-bottom: 30px;
}

h3.on {
  display: none !important;
}

div.on {
  display: block !important;
  align-items: baseline;
}

h4.on {
  display: block !important;
}

p.on {
  display: none !important;
}

.rec-table {
  width: 85%;
}

.no-margin {
  margin-left: -34px;
  height: auto;
  margin-bottom: 30px;
}

/* PRICES */

.price-box {
  background-color: #ffffff;
  width: 100%;
  height: 1300px;
  margin-top: 100px;
}

.plan-N-detail-bicon {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 10px;
}

.false {
  width: 100%;
  height: auto;
  border: #c7c7c7 0.1px solid;
  border-radius: 20px;
  background-color: #ffffff;
  margin: 10px;
  margin-top: 40px;
}

.true {
  width: 100%;
  height: auto;
  border: #c7c7c7 0.1px solid;
  border-radius: 20px;
  background-color: #1e90ff;
  margin: 10px;
  margin-bottom: auto;
}

.true p {
  color: #ffffff !important;
}

.true span {
  color: #ffffff !important;
}

.true button {
  color: #1e90ff !important;
  background-color: #ffffff !important;
}

.true button:hover {
  color: #ffffff8c;
  background-color: #05476500 !important;
  border-color: #ffffff;
  transition: 0.5s;
  color: #ffffff !important;
  background-color: #05476500 !important;
  border-color: #ffffff !important;
  transition: 1s;
}

.true h5 {
  color: #ffffff !important;
}

.true p {
  color: #ffffff !important;
}

.true h3 {
  color: #ffffff !important;
}

.true h4 {
  color: #ffffff !important;
}

.true i {
  color: #ffffff !important;
}

.prices {
  width: 100%;
  margin: 0 auto 0 auto;
}

.flex-prices {
  display: flex;
  justify-content: center;
}

.detach-off {
  height: auto;
  border: #c7c7c7 0.1px solid;
  background-color: #ffffff;
  margin-top: 40px;
}

.detach {
  height: auto;
  border: #c7c7c7 0.1px solid;
  background-color: #1250b3;
  margin-bottom: auto;
}

.detach p {
  color: #ffffff !important;
}

.detach span {
  color: #ffffff !important;
}

ul.no-margin {
  margin-left: -2rem;
  height: auto;
}

.detach button {
  color: #1250b3 !important;
  background-color: #ffffff !important;
}

.detach button:hover {
  color: #ffffff8c;
  background-color: #05476500 !important;
  border-color: #ffffff;
  transition: 0.5s;
  color: #ffffff !important;
  background-color: #05476500 !important;
  border-color: #ffffff !important;
  transition: 1s;
}

.aoiwd {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

.detach h5 {
  color: #ffffff !important;
}

.detach p {
  color: #ffffff !important;
}

.detach h3 {
  color: #ffffff !important;
}

.detach h4 {
  color: #ffffff !important;
}

.detach i {
  color: #ffffff !important;
}

.background {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  overflow: auto;
}

.chosse-title {
  text-align: center !important;
  font-family: Poppins-medium;
  color: #303030da;
  opacity: 1;
  margin-top: 5px;
  transition: all 0.3s;
}

.imgpix {
  width: 75%;
}

.chosse-button p {
  color: #707070;
  font-family: Poppins-medium;
  font-size: 12px;
}

.chosse-disabled {
  opacity: 0.4;
}

.chosse-disabled:hover {
  opacity: 0.4 !important;
  box-shadow: 0 0 0 0 !important;
}

.chosse-button {
  width: 100%;
  padding-top: 10px;
  height: 140px;
  border: #787878 solid 2px;
  border-radius: 4px;
  filter: brightness(60%);
  margin: 10px;
  background-color: rgba(220, 220, 220, 0);
  transition: all 0.3s;
}
.chosse-button:hover {
  opacity: 0.93;
  box-shadow: 4px 6px 4px 5px #00000024;
}
.background {
  overflow: auto;
}

.background::-webkit-scrollbar {
  width: 0.1px;
  /* Largura da barra de rolagem */
}

.background::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Cor de fundo da barra de rolagem */
}

.background::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Cor do "polegar" da barra de rolagem */
}

.plan-N-title {
  font-family: Poppins-Black;
  font-size: 27px;
  margin-top: 5px;
  margin-bottom: -10px;
  text-align: center;
  font-size: 24px;
  letter-spacing: -1px;
}

.plan-N-parag {
  font-family: Poppins-Medium;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 40px;
  height: 50px;
}

.plan-N-price {
  font-family: Roboto-Regular;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 0px;
  display: flex;
  align-items: baseline;
}

.plan-N-priceH3 {
  font-family: Poppins-Black;
  font-size: 32px;
  margin-top: 40px;
}

.plan-N-priceH4 {
  font-family: Poppins-Black;
  font-size: 32px;
  margin-top: 86px;
  display: none;
}

.plan-N-price_description {
  font-family: Roboto-Regular;
  font-size: 16px;
  margin-left: 10px;
  margin-top: -10px;
}

.plan-details {
  display: block;
}

.plan-N-detailh5 {
  font-family: Poppins-Black;
  font-size: 16px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-top: 60px;
}

.plan-N-butn {
  height: 36px;
  font-size: 14px;
  border-radius: 5px !important;
  font-family: Poppins-Medium;
  font-weight: lighter;
  color: white !important;
  border: #a8a6ac solid 1px !important;
  margin-top: 40px;
}

.copyright {
  text-align: center;
  font-family: Roboto-Regular;
  margin-top: 25px;
  font-size: 13px;
  color: #3c3c3c;
  opacity: 0.8;
}

.link_jr {
  color: #606060;
  text-decoration: none;
}

.link_jr:hover {
  color: #000000;
  text-decoration: underline;
}
.medium-txtAlin-black {
  color: black;
  text-align: center;
  font-family: poppins-medium;
}
.small-txtAlin-gray {
  color: rgba(0, 0, 0, 0.681);
  text-align: center;
  font-family: poppins-medium;
  font-size: 0.94rem;
}

.CCD-dad {
  display: flex;
  justify-content: center;
}
@media (max-width: 439px) {
  .media_chossetipe {
    padding-inline: 0px !important;
  }
}
.CodigoCompra-dad {
  background-color: #010110e1;
  color: white;
  border-radius: 5px;
  text-align: center;
  width: 80%;
}
.success-icon {
  height: 120px;
  width: auto;
  text-align: center;
}
.success-icon-dad {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}

.plan-N-butn2 {
  height: 50px;
  color: #1351b3 !important;
  font-size: 16px;
  font-family: Poppins-Medium;
  font-weight: lighter;
  background-color: #ffffff !important;
  margin-left: 20px;
  border: 0px transparent !important;
  margin-right: 30px;
}

.button-flex {
  display: flex;
  width: auto;
  flex-direction: row-reverse;
}

.button-aling {
  justify-content: center;
  width: auto;
  display: flex;
}

.plans-container {
  margin: 15px;
}

.div-icon {
  background-color: rgba(209, 218, 248, 0.116);
  width: 38.7px;
  height: 45.3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-right: none;
}

.plans {
  position: relative;
  border-radius: 7px;
  margin: 8px;
  padding: 10px;
  padding-bottom: 0px;
  margin-top: 40px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.051);
  transition: box-shadow 0.3s;
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-box {
  background-color: yellow;
  height: 300px;
}

.content-box {
  background-color: black;
  color: white;
  text-align: center;
  width: 200px;
}

.cir-border {
  border-radius: 100%;
  background-color: #06557c;
}

.space-left {
  margin-left: 45px;
}

.flex-EDCVC {
  display: flex;
  justify-content: center;
}

.form-group-card {
  width: 48%;
}

.flex-alings {
  width: 75%;
  display: flex;
  justify-content: space-between;
}

.space-right {
  margin-right: 45px;
}

.vant-title {
  font-family: Poppins-Black;
  font-size: 4rem;
  text-align: center !important;
  color: #000000c0;
}

.vant-display-flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.con-mysign {
  margin-top: 100px;
  margin-bottom: 100px;
}

.con-hr {
  margin: 0 auto 0 auto;
  width: 75rem;
  color: rgba(0, 0, 0, 0.566);
}

.con-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.vant-box {
  background-color: #f0f0f0;
  width: 100%;
  height: 680px;
  margin-top: 10px;
}

.inv-br {
  margin-top: -10px;
}

#img2 {
  width: 445px;
  height: auto;
  border-radius: 0px;
  border: 000.1mm solid #c7c7c7;
}

.display-flex {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.display-flex-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.display-middle {
  display: flex;
  justify-content: normal;
  flex-direction: row;
  align-items: center;
}

.con-parag {
  font-family: Quicksand;
  width: 1200px;
  font-size: 2rem;
  text-align: center;
}

.vant-parag {
  font-family: Quicksand;
  font-size: 2rem;
}

.aling-pp {
  margin-left: 5px;
}

.plan-span {
  font-family: Roboto-Regular;
}

.plan-hr {
  margin: 6px;
  color: #8787878f;
}

.vant-ul {
  width: 700px;
  border-radius: 10px;
  font-size: 20px;
}

.vant-sub-title {
  font-family: Poppins-Black;
  font-size: 1.9rem;
  text-align: center !important;
  color: #000000c0;
}

.home-middle {
  background-color: #ffffff;
  height: 160px;
  margin-top: 30px;
}

.home-middle2 {
  background-color: rgb(255, 255, 255);
  height: 1000px;
}

.btn-button {
  border-radius: 15px;
  width: 75%;
  margin-inline: 4px;
}

.halver2:hover {
  color: #9c9c9c !important;
  background-color: #05476500 !important;
  border-color: #afafaf !important;
  transition: 0.4s;
}

.buton-flex {
  width: 100% !important;
}

.buton-flex2 {
  width: 100% !important;
}

.button-flex {
  width: 78% !important;
  flex-direction: row-reverse;
  justify-content: center;
}

.button-flex2 {
  width: 98% !important;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.halver:hover {
  background-color: #ffffff09 !important;
  border-color: #ffffff88 !important;
  transition: 0.5s;
  color: #ffffff !important;
  background-color: #1351b3 !important;
  border-color: #c4c4c4 !important;
  transition: 0.4s;
}

.bg-home-start {
  background-color: #1351b3;
  color: rgb(252, 252, 252);
  height: 450px;
}

.banner-title {
  font-family: Poppins-Black;
  width: 450px;
  font-size: 3rem;
  margin-top: 40px;
}

.banner-butn {
  height: 50px;
  color: #1351b3;
  font-size: 16px;
  font-family: Poppins-Medium;
  font-weight: lighter;
}

.title-sucess {
  font-family: Roboto-Bold;
  font-size: 1.8rem;
  text-align: center;
  font-weight: lighter;
  color: #375078;
}

.banner-butn2 {
  height: 50px;
  color: #ffffff !important;
  font-size: 16px;
  font-family: Poppins-Medium;
  font-weight: lighter;
  background-color: #1351b3 !important;
  margin-left: 15px;
}

.banner-bg {
  background-image: url("imagens/celular-mão-nobg.png");
  background-repeat: no-repeat;
  background-position: right top -49px !important;
  background-size: 500px;
  width: 1200px;
  height: 500px;
}

.banner-img {
  margin-bottom: -49px;
}

.banner-intro {
  margin-left: 50px;
}

.banner-parag {
  font-family: Quicksand;
  width: 500px;
  font-size: 1.3rem;
}

@font-face {
  font-family: "Poppins-Black";
  src: url("fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto-Light";
  src: url("fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Quicksand";
  src: url("fonts/Quicksand_Light.otf");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("fonts/Poppins-Regular.ttf");
}
</style>