import axios from 'axios'


export const http = axios.create({
    baseURL: window.location.origin + "/api",
    // baseURL: "https://mysign.com.br/api",
    headers: {
        'Content-Type': 'application/json'
    }
});
console.log(window.location.origin)

