<template>
  <div class="pos-banner">
    <div class="fixed">
      <div class="Zap-buttom">
        <div class="reveal-hover">
          <a
            target="_blank"
            :href="db3.model_zap.link_direction"
            class="whatsapp-parag"
          >
            {{ db3.model_zap.title }}
          </a>
        </div>
        <button class="fix-sap-bg" @click="whatsapp()">
          <i
            :href="db3.model_zap.link_direction"
            class="bi bi-whatsapp fix-sap"
          ></i>
        </button>
      </div>
    </div>
    <div class="">
      <!-- Banner -->
      <div class="bg-home-start" style="background-color: #0000000f !important">
        <div class="banner-limit">
          <div
            class="banner-intro"
            :style="{ 'background-image': `url(${db3.banner.img})` }"
          >
            <div class="center-aling">
              <div class="banner-margin">
                <br />
                <h1 v-if="db3.banner.title != null" class="banner-title">
                  {{ db3.banner.title }}
                </h1>
                <h1
                  v-if="db3.banner.type_writer.active == true"
                  class="banner-title"
                  style="font-size: 2.9rem"
                >
                  <VueWriter
                    style="margin-bottom: 15px !important"
                    :delay="2200"
                    :intervals="20"
                    :typeSpeed="90"
                    :start="20"
                    :eraseSpeed="50"
                    :caret="underscore"
                    :array="db3.banner.type_writer.writes"
                  />
                </h1>
                <h3
                  v-if="db3.banner.subtitle != null"
                  class="banner-title"
                  style="
                    font-size: 2rem !important;
                    margin-bottom: 15px !important;
                    font-family: Poppins-Medium;
                  "
                >
                  {{ db3.banner.subtitle }}
                </h3>

                <p class="banner-parag" style="margin-top: 10px !important">
                  {{ db3.banner.parag }}
                </p>
                <div class="button-display-flex">
                  <div v-for="button in db3.banner.buttons" :key="button.id">
                    <button
                      @click="handleButtonClick(button)"
                      :style="`background-color: ${db3.color_palet[Palet].destach_areas}; color: ${db3.color_palet[Palet].destach_areas} ; border-color: ${db3.color_palet[Palet].destach_areas} ;`"
                      v-bind:class="{
                        'transparent halver2': button.type === 'transparent',
                        'white halver': button.type === 'white',
                      }"
                      class="btn margin-right btn-button banner-button btn-outline-light"
                      type="submit"
                    >
                      <span class="button-title">{{ button.title }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <div
        v-if="db3.config.layout.widgets == true"
        class=""
        :style="`background-color: ${db3.color_palet[Palet].destach_areas} !important;`"
        style="box-shadow: rgb(0 0 0 / 72%) 0px 0px 30px 0px"
      >
        <div
          :style="`border: ${db3.color_palet[Palet].destach_areas} 24px solid !important;`"
          class="mater"
        >
          <div
            class="layer-flex"
            :style="`background-color: ${db3.color_palet[Palet].destach_areas}`"
          >
            <div
              class="layer-P"
              :style="`background-color: ${db3.color_palet[Palet].destach_areas}`"
              v-for="widgets in db3.info_widget"
              :key="widgets.index"
            >
              <div class="opsition">
                <i
                  style="color: #0d346a"
                  class="bi check-layer"
                  :class="widgets.icon"
                ></i>
                <span class="aling-pp dtl-parag layer-span">
                  {{ widgets.title }}</span
                >
              </div>
              <p class="back-layer">
                {{ widgets.description }}
              </p>
            </div>
            <div class="divsor"></div>
          </div>
        </div>
      </div>
      <div class="home-middle2 pos-banner">
        <div class="">
          <!-- SimplePad -->
          <div
            class="simple-text-pad reveal"
            :class="pad.img_on_right == true ? 'Rright' : 'Rleft'"
            v-for="pad in db3.simple_pad"
            :key="pad.index"
            v-show="pad.active"
          >
            <div
              class="simple-text-pad-flex"
              :class="pad.img_on_right == true ? 'flex-column-reverse' : ''"
            >
              <img :src="pad.img" alt="logo" />

              <div class="text-area">
                <h3
                  :style="`color: ${db3.color_palet[Palet].destach_areas} !important; `"
                >
                  {{ pad.destach_title }}
                  <span style="font-family: Poppins-Light !important">{{
                    pad.title
                  }}</span>
                </h3>
                <p
                  :style="`color: ${db3.color_palet[Palet].destach_areas}; !important`"
                >
                  {{ pad.text }}
                </p>
              </div>
            </div>
          </div>

          <!-- destachArea -->
          <div
            v-if="db3.config.layout.destach_area == true"
            :style="`background: ${db3.color_palet[Palet].destach_areas} !important; `"
            class="destach-area"
          >
            <div class="">
              <h1 class="reveal Rtop">{{ db3.destach_area.title }}</h1>
              <h2 class="reveal Rtop">{{ db3.destach_area.description }}</h2>
              <div class="flex">
                <img
                  :src="db3.destach_area.img"
                  class="reveal Rright"
                  style="z-index: 2"
                />
                <div class="text-area reveal Rleft">
                  <ul
                    :style="`background: ${db3.color_palet[Palet].destach_areas} !important; `"
                  >
                    <li v-for="list in db3.destach_area.list" :key="list.index">
                      {{ list }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- FERRAMENTAS -->
          <div
            v-if="db3.config.layout.ferraments == true"
            style="margin-top: 40px; margin-bottom: 70px"
          >
            <div
              :style="`background-color: ${db3.color_palet[Palet].destach_areas} !important;`"
              class="price-intro"
              v-waypoint
            >
              <div class="price-bg reveal Rtop">
                <h1 class="price-title">
                  {{ db3.ferraments.title }}
                </h1>
                <p class="price-parag">
                  {{ db3.ferraments.subtitle }}
                </p>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                  "
                >
                  <div
                    v-for="ferm in db3.ferraments.cards"
                    :key="ferm.index"
                    class="sub-intro"
                  >
                    <div
                      style="margin-right: 20px !important"
                      class="price-intro-Dflex"
                    >
                      <i
                        :style="`color: ${db3.color_palet[Palet].destach_areas} !important;`"
                        :class="ferm.icon"
                        class="bi price-icon"
                      ></i>
                      <div>
                        <p
                          :style="`color: ${db3.color_palet[Palet].destach_areas} !important;`"
                          class="price-subtitle"
                        >
                          {{}}
                        </p>
                        <h4
                          :style="`color: ${db3.color_palet[Palet].destach_areas} !important;`"
                          class="price-subtitle-color"
                        >
                          {{ ferm.title }}
                        </h4>
                      </div>
                    </div>
                    <p
                      style="margin-right: 20px !important"
                      class="price-subparag"
                    >
                      {{ ferm.text }}
                    </p>
                  </div>
                </div>
                <div v-if="db3.contract.video.active" class="sign-box"></div>
                <div v-if="db3.contract.button.active" class="button-sign-box">
                  <button
                    @click="whatsapp()"
                    :style="`background-color: ${db3.color_palet[Palet].destach_areas} `"
                    class="btn btn-button halver2 sign-button btn-outline-light"
                    type="submit"
                  >
                    {{ db3.contract.button.name }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Recursos -->
          <div class="pos-banner" v-if="db3.config.layout.resources == true">
            <div ref="rec" style="margin-top: 20px"></div>
            <div class="rec-display-flex rec" v-scroll-slide>
              <div class="">
                <img :src="db3.resourcer_main.img" class="rec-img" alt="" />
              </div>

              <div class="rec-box container row-top">
                <!-- Roll top  -->

                <div class="rec-display-true">
                  <div class="rec-T-parag">
                    <p>
                      {{ db3.resourcer_main.parag }}
                    </p>
                  </div>
                </div>
                <!-- Roll top -->
                <div>
                  <h1 class="rec-title reveal Rleft">
                    {{ db3.resourcer_main.title
                    }}<span
                      :style="`color: ${db3.color_palet[Palet].destach_areas} !important; `"
                      style="font-weight: bolder"
                      >{{ db3.resourcer_main.destach_title }}</span
                    >
                  </h1>
                  <div
                    class="rec-display-false collapse-vertical reveal Rright"
                  >
                    <div class="rec-T-parag">
                      <p>{{ db3.resourcer_main.parag }}</p>
                    </div>
                  </div>

                  <div class="rec-display-grid targetRef reveal Rtop">
                    <div
                      class="cards-box"
                      style="position: relative"
                      v-for="card in db3.resources"
                      :key="card.id"
                    >
                      <h6
                        v-if="card.new == true"
                        class="new-card"
                        :style="`background-color: ${db3.color_palet[Palet].destach_areas}`"
                      >
                        Novo
                      </h6>
                      <div>
                        <div class="rec-displays">
                          <i
                            :style="`color: ${db3.color_palet[Palet].destach_areas} !important; border-color: ${db3.color_palet[Palet].destach_areas} !important;`"
                            :class="card.icon"
                            class="rec-icon"
                          ></i>
                          <p class="rec-sub-titles">{{ card.title }}</p>
                        </div>
                        <div class="content-box">
                          <p class="rec-parag">
                            {{ card.description }}
                            <a
                              v-if="card.info !== '' || card.info_link !== ''"
                              class="bi-info-circle rec-info-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              :href="
                                card.info_link !== ''
                                  ? card.info_link
                                  : '#targetRef'
                              "
                              :title="card.info"
                            ></a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- special_plan -->
          <div
            v-if="db3.config.layout.specialist_plan == true"
            :style="`background-color: ${db3.color_palet[Palet].destach_areas} !important;`"
            class="price-intro"
            v-waypoint
          >
            <div class="price-bg reveal Rtop">
              <h1 class="price-title">
                {{ db3.contract.title }}
              </h1>
              <p class="price-parag">
                {{ db3.contract.parag }}
              </p>
              <div style="display: flex; justify-content: center">
                <div
                  v-show="db3.contract.person.active"
                  class="sub-intro"
                  style="max-width: 600px !important"
                >
                  <div class="price-intro-Dflex">
                    <i
                      :style="`color: ${db3.color_palet[Palet].destach_areas} !important;`"
                      :class="db3.contract.person.icon"
                      class="bi price-icon"
                    ></i>
                    <div>
                      <p
                        :style="`color: ${db3.color_palet[Palet].destach_areas} !important;`"
                        class="price-subtitle"
                      >
                        {{ db3.contract.person.title }}
                      </p>
                      <h4
                        :style="`color: ${db3.color_palet[Palet].destach_areas} !important;`"
                        class="price-subtitle-color"
                      >
                        {{ db3.contract.person.detach_title }}
                      </h4>
                    </div>
                  </div>
                  <p class="price-subparag">
                    {{ db3.contract.person.parag }}
                  </p>
                  <button
                    @click="whatsapp()"
                    :style="`background-color: ${db3.color_palet[Palet].destach_areas} `"
                    class="btn btn-button halver2 price-button btn-outline-light"
                    type="submit"
                  >
                    {{ db3.contract.person.button }}
                  </button>
                </div>
              </div>
              <div v-if="db3.contract.video.active" class="sign-box"></div>
              <div v-if="db3.contract.button.active" class="button-sign-box">
                <button
                  @click="whatsapp()"
                  :style="`background-color: ${db3.color_palet[Palet].destach_areas} `"
                  class="btn btn-button halver2 sign-button btn-outline-light"
                  type="submit"
                >
                  {{ db3.contract.button.name }}
                </button>
              </div>
            </div>
          </div>

          <!-- products -->
          <div class="" v-if="db3.config.layout.products == true">
            <div class="plans-t-box" v-scroll-reveal.reset>
              <h2
                :style="`background-color: ${db3.color_palet[Palet].destach_areas}; border-color: ${db3.color_palet[Palet].destach_areas}`"
                class="plans-title reveal Rtop"
              >
                Produtos
              </h2>
            </div>
            <div class="products products-box">
              <div
                class="product reveal Rtop"
                v-for="product in db3.products"
                :key="product.index"
              >
                <div class="product-flex">
                  <img
                    class="product-img"
                    :src="product.img"
                    :alt="product.title"
                  />
                  <div class="product-text">
                    <h2>{{ product.title }}</h2>
                    <p>
                      {{ product.text }}
                    </p>
                    <ul>
                      <li v-for="list in product.list" :key="list.index">
                        {{ list }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="product-button">
                  <a style="" :href="product.link" target="__blank">
                    <button
                      :style="`background-color: ${db3.color_palet[Palet].destach_areas}`"
                      class="btn btn-button halver2 btn-outline-light"
                    >
                      Ver Mais
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="reveal Rtop" v-if="db3.config.layout.plans == true">
            <div ref="prices" class="ref-prices"></div>
            <div class="plans-t-box" v-scroll-reveal.reset>
              <h2
                :style="`background-color: ${db3.color_palet[Palet].destach_areas} ; border-color: ${db3.color_palet[Palet].destach_areas}`"
                class="plans-title reveal Rtop"
              >
                {{ db3.contract.plan_title }}
              </h2>
            </div>
            <div id="preços" class="price-box max-width" v-scroll-slide>
              <div class="prices flex-prices">
                <!-- Planos -->
                <div
                  v-for="plan in getPlan"
                  :key="plan.index"
                  :style="
                    plan.deatch == true
                      ? `border-color: ${db3.color_palet[Palet].destach_areas};`
                      : ''
                  "
                  :class="{
                    'detach detach-off': plan.deatch == true,
                    'detach-off': plan.deatch == false,
                  }"
                  class="plans reveal Rtop"
                >
                  <div>
                    <div
                      style="
                        background-color: #0d346a;
                        color: white;
                        padding: 2px;
                        padding-bottom: 15px !important;
                      "
                      class="plan-title plan-flex"
                    >
                      <h4 class="plan-N-title">{{ plan.title }}</h4>
                    </div>
                    <div class="plan-description">
                      <p
                        style="
                          text-align: center;
                          margin: 15px 0 -15px 0 !important;
                        "
                        class="plan-N-parag"
                      >
                        {{ plan.description }}
                      </p>
                    </div>
                    <div style="padding-inline: 10px" class="price">
                      <div class="plan-N-price">
                        <h3 class="plan-N-priceH3 price-valor">
                          {{ formatCurrency(plan.annual_price_installment) }}
                        </h3>
                        <p class="plan-N-priceP">/mês</p>
                      </div>
                      <p class="plan-N-price_description">
                        parcela única de
                        <strong>{{ formatCurrency(plan.annual_price) }}</strong>
                        por ano
                      </p>
                    </div>
                    <div style="padding-inline: 10px" class="detail">
                      <h5 class="plan-N-detailh5" v-if="plan.details !== null">
                        O que está incluso:
                      </h5>
                      <div>
                        <ul class="no-margin">
                          <li
                            v-for="detail in plan.details"
                            :key="detail"
                            class="plan-N-detail-bicon"
                          >
                            <i
                              style="color: #0d346a"
                              class="bi bi-check-lg check-details"
                            ></i>
                            <span class="aling-pp dtl-parag plan-span">{{
                              detail
                            }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="button"></div>
                  </div>
                  <div class="butn-aling">
                    <button
                      :style="`background-color: ${db3.color_palet[Palet].destach_areas}; color: ${db3.color_palet[Palet].destach_areas} ; border-color: ${db3.color_palet[Palet].destach_areas} ;`"
                      class="btn btn-button plan-N-butn halver2 btn-outline-secondary"
                      @click="
                        plan.through_link == true
                          ? contractContact(plan)
                          : contract(plan.product_plan_id)
                      "
                      type="submit"
                    >
                      Contratar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Perguntas frequentes -->
          <div
            ref="perguntas_frequentes"
            class="perguntas-frequentes"
            style="
              margin: 2rem;
              margin-bottom: 3.5rem !important;
              display: flex;
              justify-content: center;
            "
            v-if="db3.config.layout.ansawer_quest == true"
          >
            <div style="max-width: 900px !important; width: 100%">
              <h2
                class="reveal Rtop"
                style="text-aling: center; margin-left: 10px"
              >
                {{ db3.answer_quest.title }}
              </h2>
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div
                  v-for="(pf, index) in db3.answer_quest.dropdown"
                  :key="index"
                  class="accordion-item reveal ARtop"
                  style=""
                >
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="`#collapse${index}`"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <li style="display: block">
                        <strong class="li-strong-pf">{{ pf.questions }}</strong>
                      </li>
                    </button>
                  </h2>
                  <div
                    :id="`collapse${index}`"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      {{ pf.answer }}
                      <a v-show="pf.link != ''" :href="pf.link">Saiba Mais</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="integration-area"
            v-if="db3.config.layout.integration == true"
            :style="`color: ${db3.color_palet[Palet].destach_areas};`"
          >
            <h2 class="reveal Rtop">{{ db3.integration.title }}</h2>
            <div class="d-flex">
              <div
                class="column reveal Rtop"
                v-for="list in db3.integration.list"
                :key="list.index"
              >
                <i :class="list.icon" class="bi"></i>
                <p>{{ list.text }}</p>
              </div>
            </div>
          </div>
          <div class="clients" v-if="db3.config.layout.clients == true">
            <div
              :style="`background-color: ${db3.color_palet[Palet].destach_areas};`"
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <h2 class="reveal Rtop">{{ db3.clients.title }}</h2>
              <div class="carousel-inner reveal Rtop">
                <div
                  class="carousel-item"
                  :class="{ active: index === 0 }"
                  :data-bs-interval="db3.clients.interval"
                  v-for="(client, index) in db3.clients.clients"
                  :key="index"
                >
                  <div class="img-box">
                    <img :src="client.logo" width="" :alt="client.name" />
                  </div>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Voltar</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Proximo</span>
              </button>
            </div>
          </div>
          <div class="Footer reveal Rtop">
            <GlobalFooter />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent } from "vue";
import GlobalFooter from "../components/GlobalFooter.vue";
import "bootstrap-icons/font/bootstrap-icons.css";
import { data } from "../App.vue";
import "../plugins/ReavealScroll";
import VueWriter from "vue-writer";

export default defineComponent({
  name: "HomePage",
  // ?data
  components: {
    GlobalFooter,
    VueWriter,
  },
  data() {
    return {
      Palet: "DarkBlue",
      itemId: null,
      revealDiv: false,
      db3: data,
      isExpanded: false,
      modelON: false,
    };
  },
  mounted() {
    console.log(this.db3);

    if (this.$route.hash == "#price") {
      this.scrollToDiv();
    }
    if (this.$route.hash == "#resoucer") {
      this.scrollToRec();
    }
    if (this.$route.hash == "#perguntas-frequentes") {
      this.scrollToPF();
    }
  },
  created() {
    this.toScrollToDiv();
    this.Palet = this.db3.color_palet.Select;
  },
  methods: {
    contractContact(plan) {
      window.open(
        `https://whatsa.me/553832168837/?t=Ol%C3%A1!%20Tenho%20interesse%20no%20plano%20*${plan.title}*%20do%20MySign.%20Gostaria%20de%20falar%20com%20a%20equipe%20*Comercial*.`,
        "_blank"
      );
    },
    scrollToPF() {
      if (this.$refs.perguntas_frequentes instanceof HTMLElement) {
        this.$refs.perguntas_frequentes.scrollIntoView({ behavior: "smooth" });
      }
    },
    formatCurrency(value) {
      if (!value) return "R$ 0,00";
      const formattedValue = (value / 1).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formattedValue;
    },
    async contract(product_plan_id) {
      this.db3.contracts.find(
        (item) => item.product_plan_id === product_plan_id
      );
      console.log(product_plan_id);
      this.$router.push(`/contract/${product_plan_id}`);
    },
    onWaypoint(entry) {
      if (entry.isIntersecting) {
        entry.target.classList.add("slide-in");
      } else {
        entry.target.classList.remove("slide-in");
      }
    },
    toScrollToDiv() {
      if (this.$route.hash == "#price") {
        this.scrollToDiv();
      }
      if (this.$route.hash == "#resoucer") {
        this.scrollToRec();
      }
      if (this.$route.hash == "#perguntas-frequentes") {
        this.scrollToPF();
      }
    },
    expandBox() {
      this.isExpanded = true;
    },
    closeBox() {
      this.isExpanded = false;
    },
    scrollToDiv() {
      if (this.$refs.prices instanceof HTMLElement) {
        this.$refs.prices.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToRec() {
      if (this.$refs.rec instanceof HTMLElement) {
        this.$refs.rec.scrollIntoView({ behavior: "smooth" });
      }
    },
    whatsapp() {
      window.open(this.db3.model_zap.link_direction, "_blank");
    },
    handleButtonClick(button) {
      if (button.action === "model") {
        this.whatsapp();
      } else if (button.action === "price") {
        this.scrollToDiv();
      }
    },
  },
  watch: {
    "$route.params"() {
      this.toScrollToDiv();
    },
  },
  computed: {
    getPlan() {
      return this.db3.contracts.map((contract) => {
        if (!Array.isArray(contract.details))
          contract.details = JSON.parse(contract.details);
        console.log(contract);
        return contract;
      });
    },
  },
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ?PLANS */

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-column {
  flex: 0 0 25%;
  margin-bottom: 20px;
}
.li-strong-pf {
  font-family: Poppins-medium;
}
.logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.Footer {
  margin-top: 0px;
}

.company-name {
  font-size: 14px;
}

.links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.links li {
  margin-bottom: 5px;
}

.links a {
  color: #fff;
  text-decoration: none;
}

.social-media {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.social-media li {
  margin-right: 10px;
}

.whatsapp-number {
  font-size: 14px;
  margin-top: 10px;
}

.fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.model {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99992;
}

.model-base {
  width: 590px;
  height: 450px;
  background-color: white;
  border-radius: 15px;
}

@media (max-width: 550px) and (min-width: 420px) {
  .model-base {
    width: 450px !important;
  }
}

@media (max-width: 420px) and (min-width: 320px) {
  .model-base {
    width: 350px !important;
    height: 400px;
  }
}

.buttom-close-model {
  font-size: 2rem;
  cursor: pointer;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.esc-box:hover {
  color: #ffffff;
  background-color: red;
  transition: all 0.3s;
}

.top-info {
  background-color: #f0f0f0;
  width: 100%;
  height: 50px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.M-I-ZapJRC {
  background-color: #ffffff;
  height: 27px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  margin-right: 15px;
}

#JRC-img {
  height: auto;
  width: 50%;
}

.JRC-divImg {
  align-items: center;
  justify-content: center;
  display: flex;
}

.M-center-zap {
  position: absolute;
  bottom: 15px;
  border-radius: 30px;
  background-color: #35b96a;
  padding-right: 10px;
}

.JRC-display-flex {
  align-items: center;
  justify-content: center;
  display: flex;
}

.JRC-parag {
  font-family: Poppins-Medium;
  font-size: 18px;
  max-width: 80%;
  text-align: center;
}

.JRC-aling {
  align-items: center;
  justify-content: center;
  display: flex;
}

.JRC-a-parag {
  font-family: Poppins-Medium;
  font-size: 18px;
  text-align: center;
  color: #35b96a;
}

.AlinkZap {
  font-family: Poppins-light;
  text-decoration: none;
  font-size: 20px !important;
  color: #ffffff;
}

.center {
  width: 100%;
  height: 80%;
  margin: 0 auto 0 auto;
}

.M-center-iconzap {
  color: #35b96a;
  font-size: 24px;
}
@media (max-width: 460px) {
  .perguntas-frequentes {
    margin: 5px !important;
  }
  .perguntas-frequentes li {
    display: block !important;
    margin-left: 5px;
  }
}

.M-center-title {
  margin-top: 20px;
  font-family: Poppins-Black;
  color: #19192a;
  text-align: center;
}

.esc-box {
  color: #262626c0;
  background-color: #f0f0f0;
  right: 0;
  margin: 5px;
  position: absolute;
  transition: all 0.4s ease;
  width: 80px;
  border-radius: 5px;
  height: 34px;
}

.model-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: black;
  opacity: 0.5;
}

.base-overflow {
  height: 100%;
}

.fix-sap {
  color: #ffffff;
  font-size: 1.8rem;
  transition: transform 0.3s ease;
  display: inline-block;
  /* Adicione esta linha */
}

.Zap-buttom {
  display: flex;
  align-content: center;
  align-items: center;
}

.slide-in {
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in;
}

.slide-in.show {
  transform: translateX(0);
}

.fixed:hover .reveal-hover {
  opacity: 1;
  width: 280px;
  height: 50px;
  background-color: #35b96a;
}

.whatsapp-parag {
  color: white;
  margin: 3px;
  width: 240px;
  padding-inline: 0px;
  position: absolute;
  right: 6px;
  bottom: 9px;
  margin-right: 10px;
  margin-left: -10px;
  font-size: 20px;
  height: 25px;
  margin-bottom: 4px;
  overflow: hidden;
  text-decoration: none 0.01px #ffffff4e;
  font-family: Roboto-medium;
  z-index: 30;
  transition: all 0.5s;
}

.whatsapp-parag:hover {
  color: #f6f6f6e4;
  text-decoration: underline 2px #dcdcdc4e;
}

.reveal-hover {
  opacity: 0;
  width: 0px;
  height: 50px;
  transition: width 0.5s, height 0.5s, background-color 0.5s, opacity 0.8s;
  margin-right: -50px;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  z-index: 3;
}

.reveal-hover {
  display: flex;
}

.fix-sap-bg {
  background-color: #35b96a;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.24s ease;
  /* Combine as transições em uma linha */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.fixed:hover .fix-sap {
  animation: shake 0.4s;
}

.fixed:hover .fix-sap-bg {
  box-shadow: none;
  /* Altere o box-shadow para "none" */
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width: 500px) and (min-width: 400px) {
  .plans {
    width: 80% !important;
  }

  .plan-N-butn {
    width: 88% !important;
  }
}

@media (max-width: 599px) and (min-width: 500px) {
  .plans {
    width: 65% !important;
  }

  .plan-N-butn {
    width: 88% !important;
  }
}

@media (max-width: 500px) and (min-width: 400px) {
  .button {
    height: 30px !important;
  }

  .flex-prices {
    width: 100% !important;
  }

  .plan-N-parag {
    font-size: 0.76rem !important;
  }

  .plan-N-title {
    font-size: 1.3rem !important;
  }

  .price-valor {
    font-size: 2rem !important;
  }

  .plan-N-butn {
    margin-top: -10px !important;
    position: relative !important;
  }

  .plan-N-title {
    text-align: center !important;
  }
}

@media (max-width: 400px) and (min-width: 300px) {
  .button {
    height: 30px !important;
  }

  .plan-N-parag {
    font-size: 0.76rem !important;
  }

  .plan-N-title {
    font-size: 1.3rem !important;
  }

  .price-valor {
    font-size: 2rem !important;
  }

  .flex-prices {
    width: 100% !important;
  }

  .plan-N-butn {
    margin-top: -10px !important;
    position: relative !important;
  }
}

@media (max-width: 300px) and (min-width: 200px) {
  .plans {
    flex-basis: 80% !important;
  }

  .detail {
    display: none !important;
  }

  .button {
    height: 100px !important;
  }

  .flex-prices {
    width: 100% !important;
  }

  .plan-N-butn {
    margin-top: -10px !important;
    position: relative !important;
  }
}

@media (max-width: 500px) {
  .plan-flex {
    justify-content: center !important;
  }
}

/* ?REC */

.hr0,
.hr1,
.hr2,
.hr3,
.hr4 {
  display: none;
}

.slide-content {
  transition: height 0.5s;
  overflow: hidden;
}

.slide-enter-active,
.slide-leave-active {
  height: auto;
}

.slide-enter,
.slide-leave-to {
  height: 0;
}

.margin-right {
  margin-right: 15px;
}
.banner-margin {
  width: 990px;
}
.banner-intro {
  margin-top: 80px;
}

@media (max-width: 1030px) and (min-width: 645px) {
  .vant-box {
    height: 700px !important;
  }
}

@media (max-width: 1200px) and (min-width: 900px) {
  .banner-intro {
    background-position: right top 100px;
    background-size: 400px;
    height: 800px;
  }
  .banner-margin {
    padding-inline: 50px;
  }

  .banner-parag {
    width: 500px !important;
  }
}
@media (max-width: 980px) {
  .banner-margin {
    margin-top: 0px !important;
    width: auto !important;
  }
  .banner-intro {
    margin-top: 0px !important;
  }
  .banner-title {
    margin-top: 0px !important;
  }
}

@media (max-width: 980px) and (min-width: 860px) {
  .bg-home-start {
    margin-top: 0px !important;
  }
  .banner-intro {
    background-image: none !important;
    height: auto !important;
  }

  .banner-limit {
    margin-inline: 30px !important;
  }

  .banner-parag {
    width: auto !important;
  }

  .banner-title {
    width: auto !important;
  }

  .bg-home-start {
    height: auto !important;
    padding-bottom: 20px;
  }

  .banner-button {
    width: 240px !important;
    margin: 5px !important;
  }
  .banner-button {
    height: 50px !important;
    margin-top: 10px !important;
  }
}
.banner-button {
  border-radius: 7px !important;
}

@media (max-width: 860px) and (min-width: 600px) {
  .banner-intro {
    background-image: none !important;
    height: auto !important;
  }
  .bg-home-start {
    margin-top: 0px !important;
  }

  .banner-margin {
    margin-top: 30px;
  }

  .banner-limit {
    margin-inline: 30px !important;
  }

  .banner-parag {
    width: auto !important;
    text-align: center !important;
  }

  .banner-title {
    width: auto !important;
    text-align: center !important;
  }

  .bg-home-start {
    height: auto !important;
    padding-bottom: 20px;
  }

  .button-display-flex {
    justify-content: center !important;
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .banner-intro {
    background-image: none !important;
    height: auto !important;
  }
  .bg-home-start {
    margin-top: 0px !important;
  }

  .banner-margin {
    margin-top: 40px;
  }

  .banner-limit {
    margin-inline: 30px !important;
  }

  .banner-parag {
    width: auto !important;
    text-align: center !important;
  }

  .button-display-flex {
    display: block !important;
  }

  .banner-title {
    width: auto !important;
    text-align: center !important;
  }

  .bg-home-start {
    height: auto !important;
    padding-bottom: 20px;
  }

  .banner-button {
    width: 100% !important;
    margin: 0 auto 0 auto !important;
    margin-top: 10px !important;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .banner-intro {
    background-image: none !important;
    height: auto !important;
  }
  .bg-home-start {
    margin-top: 0px !important;
  }

  .banner-limit {
    margin-inline: 30px !important;
  }

  .banner-parag {
    width: auto !important;
    text-align: center !important;
  }

  .button-display-flex {
    display: block !important;
  }

  .banner-title {
    width: auto !important;
    text-align: center !important;
    font-size: 2.3rem !important;
  }

  .bg-home-start {
    height: auto !important;
    padding-bottom: 20px;
  }
  .banner-margin {
    margin-top: -10vh;
  }

  .banner-button {
    width: 100% !important;
    height: 40px !important;
    margin: 0 auto 0 auto !important;
    margin-top: 10px !important;
  }
}

/* PLANS INTRO */

@media (max-width: 999px) and (min-width: 860px) {
  .price-bg {
    background-image: none !important;
  }
}

@media (max-width: 860px) and (min-width: 600px) {
  .price-bg {
    background-image: none !important;
    width: auto !important;
  }

  .price-title {
    width: auto !important;
  }
}

@media (max-width: 600px) and (min-width: 400px) {
  .price-bg {
    background-image: none !important;
  }

  .price-bg {
    background-image: none !important;
    width: auto !important;
  }

  .banner-margin {
    margin-top: 40px;
  }

  .price-title {
    width: auto !important;
  }

  .price-button {
    width: 100% !important;
  }

  .price-subtitle {
    width: auto !important;
  }

  .sub-intro {
    width: 98% !important;
  }

  @media (max-width: 450px) and (min-width: 400px) {
    .price-icon {
      display: none;
    }
  }
}

@media (max-width: 400px) and (min-width: 260px) {
  .price-bg {
    background-image: none !important;
  }

  .banner-margin {
    margin-top: 40px;
  }

  .price-bg {
    background-image: none !important;
    width: auto !important;
  }

  .price-title {
    width: auto !important;
  }

  .price-button {
    width: 100% !important;
  }

  .price-subtitle {
    width: auto !important;
  }

  .price-icon {
    display: none;
  }

  .sub-intro {
    width: 98% !important;
  }
}

.rec8 {
  display: none;
}

.plans-container {
  margin: 2px !important;
}

/* RECURSOS: */

.rec-display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}

.plans-title {
  font-family: roboto-medium;
  color: white;
  text-align: center;
  width: auto;
  padding: 5px;
  border: solid 0.5px;
  padding-inline: 20px;
  border-radius: 11px !important;
  transition: all 0.4s;
}

.plan-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
}

.plans-title:hover {
  font-family: roboto-medium;
  color: #ffffff;
  text-align: center;
  background-color: white !important;
  color: rgba(0, 0, 0, 0.638) !important;
  border: solid 0.5px #000000;
  width: auto;
  padding: 5px;
  padding-inline: 32px;
  border-radius: 21px;
}

.plans-t-box {
  display: flex;
  margin-top: 45px;
  justify-content: center;
}

.rec-div-img {
  margin-top: 20px;
  width: 420px;
  height: 100%;
  display: flex;
  align-items: center;
}

.rec-List {
  width: auto;
  height: auto;
  margin-left: 10px;
}
.new-card {
  position: absolute;
  top: -13px;
  right: 3px;
  border-radius: 11px;
  color: white;
  padding: 4px;
  font-size: 14px;
  z-index: 2;
  font-weight: bolder;
  padding-inline: 8px;
  border: white 1px solid;
}
.expanding-box {
  position: relative;
}

.rec-img {
  height: auto;
  width: 500px;
  margin-right: 50%;
  position: relative;
}

.rec-display-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 5px;
  max-width: 650px;
  width: auto;
}

/* ?RecMedia */

@media (max-width: 1200px) and (min-width: 800px) {
  .rec-box {
    max-width: 740px !important;
    margin: 0 auto 0 auto !important;
    width: auto;
  }

  .rec {
    max-width: 90% !important;
  }

  .rec-img {
    display: none;
  }

  .row-top {
    display: flex;
    flex-direction: column-reverse;
  }

  .rec-T-parag {
    padding: 0;
    margin-top: 20px;
  }
}

.rec-display-false {
  display: block;
}

.rec-display-true {
  display: none;
}

@media (max-width: 800px) and (min-width: 500px) {
  .rec-box {
    max-width: 100% !important;
    margin: 0 auto 0 auto !important;
    width: auto;
  }

  .rec {
    max-width: 90% !important;
  }

  .rec-img {
    display: none;
  }

  .row-top {
    display: block;
    flex-direction: column-reverse;
  }

  .rec-T-parag {
    padding: 0;
    margin-top: 20px;
  }

  .rec-box {
    max-height: 100% !important;
  }

  .rec-sub-titles {
    letter-spacing: -0.6px !important;
  }

  .rec-T-parag {
    width: auto !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .rec-display-false {
    display: block !important;
  }

  .rec-display-true {
    display: none !important;
  }
}

@media (max-width: 500px) and (min-width: 300px) {
  .rec-box {
    max-width: 100% !important;
    margin: 0 auto 0 auto !important;
    width: auto;
  }

  .rec {
    max-width: 90% !important;
  }

  .rec-img {
    display: none;
  }

  .rec-title {
    text-align: left !important;
    font-size: 26px !important;
  }

  .row-top {
    display: block;
    flex-direction: column-reverse;
  }

  .rec-T-parag {
    display: none !important;
  }

  .rec-box {
    max-height: 100% !important;
  }

  .rec-sub-titles {
    letter-spacing: -0.6px !important;
  }

  .rec-T-parag {
    width: auto !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .rec-display-false {
    display: none !important;
  }

  .rec-display-true {
    display: none !important;
  }
}

@media (max-width: 300px) and (min-width: 250px) {
  .rec-box {
    max-width: 100% !important;
    margin: 0 auto 0 auto !important;
    width: auto;
  }

  .rec {
    max-width: 90% !important;
  }

  .rec-img {
    display: none;
  }

  .rec-title {
    text-align: left !important;
    font-size: 26px !important;
  }

  .row-top {
    display: block;
    flex-direction: column-reverse;
  }

  .rec-T-parag {
    display: none !important;
  }

  .rec-box {
    max-height: 100% !important;
  }

  .rec-sub-titles {
    letter-spacing: -0.6px !important;
  }

  .rec-T-parag {
    width: auto !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .rec-display-false {
    display: none !important;
  }

  .rec-display-true {
    display: none !important;
  }
}

@media (max-width: 770px) and (min-width: 575px) {
  .rec-display-grid {
    grid-template-columns: repeat(auto-fit, minmax(49%, 1fr)) !important;
    max-width: 120%;
  }

  .expanding-box {
    width: 100% !important;
  }
}

@media (max-width: 575px) and (min-width: 200px) {
  .expanding-box {
    width: auto !important;
  }

  .rec-title {
    width: auto !important;
  }

  @media (max-width: 370px) and (min-width: 200px) {
    .rec-display-grid {
      display: block;
    }
  }
}

.rec-displays {
  display: flex;
  align-items: center;
  margin: 5px;
  margin-top: 12px;
  margin-bottom: 7px;
}

.rec-icon {
  font-size: 40px;
  margin-right: 10px;
  margin-top: 0px;
  filter: brightness(50%);
  border-radius: 4px;
  font-family: Poppins-Medium;
  color: #0d346a;
  height: 58px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rec-sub-titles {
  width: auto;
  margin: 0;
  font-family: Poppins-Black;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 120%;
  color: #242a2f;
}

.recs-iten-box {
  width: 250px;
  border: solid 2px #c7c7c7b9;
  border-radius: 15px;
}

.rec-box {
  margin-top: 90px;
  margin: 0;
}

.rec-center-aling {
  flex-basis: 45%;
}

.rec-Flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.rec-center-aling {
  display: flex;
  justify-content: center;
  margin-left: 20px;
  align-items: baseline;
}

.rec-parag {
  font-family: Poppins-Regular;
  color: #757575;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.5px;
  width: auto;
  margin: 5px;
  cursor: default;
  margin-bottom: 8px;

  border-left: 2.5px solid rgba(0, 0, 0, 0.915);
  padding-left: 10px;
  margin-left: 27px;
}

.rec-T-parag {
  font-family: Roboto-medium;
  color: #000000c9;
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  width: 640px;
  padding-top: 20px;
  cursor: default;
  padding: 10px;
}

.rec-box {
  background-color: white;
  padding: 15px;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  transition: all 0.3s;
}

/* MEDIA DO LAYER */
@media (max-width: 1120px) {
  .layer-P {
    margin-inline: 30px !important;
  }
  @media (max-width: 1100px) {
    .banner-intro {
      background-image: none !important;
    }
    .banner-title {
      margin: 0px !important;
      text-align: center;
      width: 100% !important;
    }
    .banner-parag {
      margin: 0px !important;
      text-align: center;
      width: 100% !important;
    }
    .button-display-flex {
      display: flex;
      justify-content: center !important;
      margin-top: 20px;
    }
  }
  @media (max-width: 880px) {
    .banner-intro {
      background-image: none !important;
    }
    .banner-title {
      margin: 0px !important;
      text-align: center;
      width: 100% !important;
    }
    .banner-parag {
      margin: 0px !important;
      text-align: center;
      width: 100% !important;
    }
    .button-display-flex {
      display: flex;
      justify-content: center !important;
      margin-top: 20px;
    }
    .layer-span {
      font-size: 1.4rem !important;
    }
    .check-layer {
      font-size: 1.4rem !important;
    }
    .layer-P {
      padding-inline: 0px !important;
    }
  }
  @media (max-width: 690px) {
    .layer-P {
      padding-inline: 1px !important;
      margin-inline: 1px !important;
    }
    .banner-intro {
      background-image: none !important;
    }
    .banner-title {
      margin: 0px !important;
      text-align: center;
      width: 100% !important;
    }
    .banner-parag {
      margin: 0px !important;
      text-align: center;
      width: 100% !important;
    }
    .button-display-flex {
      display: flex;
      justify-content: center !important;
      margin-top: 20px;
    }
    .layer-span {
      font-size: 1.3rem !important;
    }
    .check-layer {
      font-size: 1.3rem !important;
    }
    .layer-flex {
      flex-direction: column-reverse !important;
      height: auto !important;
    }
    .divsor {
      width: 100% !important;
      height: 1px !important;
    }
  }
  @media (max-width: 340px) {
    .banner-intro {
      background-image: none !important;
    }
    .banner-title {
      margin: 0px !important;
      text-align: center;
      width: 100% !important;
    }
    .banner-parag {
      margin: 0px !important;
      text-align: center;
      width: 100% !important;
    }
    .button-display-flex {
      display: flex;
      justify-content: center !important;
      margin-top: 20px;
    }
    .layer-span {
      font-size: 1.4rem !important;
    }
    .check-layer {
      font-size: 1.4rem !important;
    }
    .back-layer {
      font-size: 0.9rem;
    }
    .layer-flex {
      flex-direction: column-reverse !important;
      height: auto !important;
    }
    .divsor {
      width: 100% !important;
      height: 1px !important;
    }
  }
  @media (max-width: 340px) {
  }
}
@media (min-width: 950px) {
  .back-layer {
    transition: all 1s !important;
    overflow: hidden !important;
    max-height: 0px;
    margin-bottom: -5px;
  }
  .layer-flex:hover .back-layer {
    max-height: 200px !important;
  }
  .bi-caret-down-fill {
    display: flex !important;
    justify-content: center;
    margin-bottom: -5px;
  }
}
.rec {
  max-width: 1300px;
  margin: 0 auto 0 auto;
  padding-bottom: 50px;
}

.bi-caret-down-fill {
  display: none;
}

.check-layer {
  color: rgba(255, 255, 255, 0.923) !important;
  font-size: 2.1rem;
  margin-right: 5px;
}
.opsition {
  display: flex;
  justify-content: center;
}

.layer-span {
  font-size: 2rem;
}
.layer-P {
  margin: 15px;
  margin-inline: 70px;
}
.back-layer {
  display: flex;
  justify-content: center;
  max-width: 400px;
  max-height: 200px !important;
  font-family: "Poppins-Regular";
  text-align: center;
}
.divsor {
  width: 1px;
  height: 150px;
  position: absolute;
  margin: 0 auto 0 auto;
  background-color: white;
}
.layer-flex {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #0d346a;
  color: rgba(255, 255, 255, 0.849);
  font-family: Poppins-medium;
  height: 130px;
}
.layer-div {
  display: flex;
  border-right: #ffffff 1px solid;
}

.rec-box {
  max-width: 740px;
}

@media (max-width: 1100px) and (min-width: 800px) {
  .rec-box {
    max-width: 740px !important;
    margin: 0 auto 0 auto !important;
    width: auto;
  }

  .rec {
    max-width: 90% !important;
  }

  .rec-img {
    display: none;
  }

  .row-top {
    display: flex;
    flex-direction: column-reverse;
  }

  .rec-T-parag {
    padding: 0;
    margin-top: 20px;
  }
}

.rec-info {
  background-color: white;
  border: none;
  color: none;
  cursor: pointer !important;
}

.rec-info-icon {
  color: #0d346a;
}

.rec-sub-parag {
  font-family: Poppins-Medium;
  color: #ffffff;
  font-size: 10px;
  width: 60%;
  height: 20px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0 auto;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
  margin-top: 15px;
}

.display-flextop {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.max-width {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto 0 auto;
}

.rec-title {
  font-family: Poppins-Light;
  font-size: 36px;
  text-align: left;
  width: 500px;
  color: #000000;
}

h3.custom_price {
  display: none !important;
}

div.custom_price {
  display: block !important;
  align-items: baseline;
}

h4.custom_price {
  display: block !important;
}

p.custom_price {
  display: none !important;
}

.rec-table {
  width: 100%;
  margin: 0 auto 0 auto;
}

/* PRICES */

.price-box {
  background-color: #ffffff;
  width: 100%;
  height: auto;
  padding-bottom: 30px;
}

.price-title {
  font-family: Poppins-Black;
  color: white;
  width: 750px;
  border-radius: 15px;
  text-align: center;
  margin: 0 auto 0 auto;
}

.price-parag {
  font-family: Poppins-Regular;
  color: rgb(255, 255, 255);
  padding-top: 20px;
  max-width: 440px;
  margin-top: -10px !important;
  border-radius: 15px;
  text-align: center;
  margin: 0 auto 0 auto;
}

@media (max-width: 1019px) {
  .price-parag {
    max-width: none !important;
    padding-inline: 50px;
  }
  @media (max-width: 516px) {
    .price-parag {
      max-width: none !important;
      padding-inline: 0px !important;
    }
  }
}

.price-intro {
  box-shadow: rgb(0 0 0 / 72%) 0px 0px 30px 0px;
  position: relative;
  z-index: 2;
  background-color: #0d346a;
  width: 100%;
  height: auto;
}

.price-bg {
  background-image: url("../components/imagens/banner-intro-background.png");
  background-repeat: no-repeat;
  margin: 0 auto 0 auto;
  max-width: 1500px;
  height: auto;
  background-size: 700px;
  background-position-x: right;
  background-position-y: center;
  padding: 40px;
}

.price-hr {
  width: 30%;
  padding-bottom: 30px;
  margin: 0 auto 0 auto;
  color: rgb(129, 129, 129);
}
@media (max-width: 450px) {
  .price-subtitle-color {
    margin-inline: 15%;
    text-align: center;
  }
  .price-subparag {
    font-family: "components/Poppins-Light" !important;
    text-align: center;
  }
  @media (max-width: 407px) {
    .price-subtitle-color {
      margin-inline: 2% !important;
      text-align: center !important;
    }
  }
}

@media (max-width: 637px) {
  .divsor {
    margin-top: -20px;
  }
}

.price-subtitle-color {
  font-family: Poppins-Medium;
  font-size: 25px;
}

.sub-intro {
  width: 80%;
  margin: 0 auto 0 auto;
  margin-top: 30px;
}

.price-subparag {
  max-width: 550px;
  width: auto;
  font-family: Roboto-regular;
  font-size: 16px;
}

.sub-intro {
  height: auto;
  padding: 10px;
  padding-right: 5px;
  background-color: #fdfdfd;
  color: #0d346a;
  border-radius: 15px;
  margin-inline: 15px !important;
  max-width: 450px;
}

.price-button {
  height: 50px;
  color: #ffffff;
  font-size: 16px;
  font-family: Poppins-Medium;
  font-weight: lighter;
}

.price-subtitle {
  font-family: Poppins-Light;
  width: 350px;
  font-size: 18px;
}

.price-icon {
  color: #0d346a;
  font-size: 34px;
  margin-right: 10px;
}

.price-intro-Dflex {
  display: flex;
  align-items: center;
}

.plan-N-detail-bicon {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 10px;
  align-items: baseline;
}
.expanding-box.reveal.active {
  max-height: 370px;
}

.detachtitle {
  font-size: 0.86rem;
  position: absolute;
  top: -11px;
  left: 0;
  /* background-color: white; */
  right: 0;
  display: flex;
  justify-content: center;
}
.detachtitle span {
  background-color: white;
  padding-inline: 10px !important;
  padding: 3px;
  border-radius: 10px;
}

.plan-flex {
  justify-content: center;
}
.plan-N-title {
  margin: 0px;
}

.detach-off {
  height: auto;
  border: #c7c7c7 0.1px solid;
  background-color: #ffffff !important;
  margin-top: 40px;
}

.detach {
  height: auto;
  border: 1px solid;
  margin-bottom: auto;
}

@media (max-width: 425px) {
  .expanding-box {
    border: 2px solid #7575751c;
    box-shadow: -1px 1px 13px 10px rgb(0 0 0 / 5%);
    border-radius: 4px;
    max-height: 100% !important;
  }
  .expanded {
    height: auto !important;
  }
  .collapsed {
    height: auto !important;
  }
}

.expanded {
  max-height: 550px !important;
}

.collapsed {
  max-height: auto;
}

.box-title {
  margin: 0;
  padding: 10px;
}

.content-box {
  display: block;
  height: auto;
}

.expanded {
  display: block;
}

ul.no-margin {
  margin-left: -2rem;
  height: auto;
}

.price {
  height: 30px;
}

.detail {
  height: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.button {
  height: 60px;
}

.detach-off button {
  color: white !important;
}

.prices {
  width: 1500px;
  margin: 0 auto 0 auto;
}

.flex-prices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
}

.flex-basis {
  flex-basis: 50%;
}

.detach.plans {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.plan-N-title {
  font-family: Poppins-Black;
  font-size: 1.2rem;
  margin-top: 15px;
  letter-spacing: -1px;
}

.plan-N-parag {
  font-family: Poppins-Light;
  font-size: 0.8rem;
  margin-left: 10px;
  margin-top: 20px;
  height: 50px;
}

.plan-N-price {
  font-family: Poppins-Light;
  font-size: 1rem;
  margin-left: 10px;
  margin-top: 40px;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.plan-N-priceH3 {
  font-family: Poppins-Black;
  font-size: 1.6rem;
  margin-top: -40px;
}

.plan-N-priceH4 {
  font-family: Poppins-Black;
  font-size: 1.4rem;
  display: none;
}

.plan-span {
  font-size: 0.75rem;
}

.check-details {
  font-size: 0.83rem;
  margin-right: -2px;
  margin-bottom: -2px;
}

.plan-N-price_description {
  font-family: Poppins-Light;
  font-size: 0.81rem;
  float: left;
  margin-left: 10px !important;
  margin-top: -10px;
}

.plan-N-detailh5 {
  font-family: Poppins-Black;
  font-size: 0.65rem;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-top: 50px;
}

.plan-N-butn {
  height: 44px;
  position: absolute;
  bottom: 10px;
  color: #ffffff;
  font-size: 16px;
  font-family: Poppins-Medium;
  font-weight: lighter;
  width: 90%;
  border: #dedede solid 1px;
  margin: 0 auto 0 auto;
}

.butn-aling {
  display: flex;
  justify-content: center;
  width: auto;
}

.plans-container {
  margin: 15px;
  width: 250px;
}

.plans {
  position: relative;
  border-radius: 12px;
  margin: 10px;
  width: 280px;
  overflow: hidden;
  margin-top: 40px;
  box-shadow: 0px 0px 14px 0px rgb(2 2 2 / 29%);
  transition: all 0.5s !important;
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-box {
  background-color: yellow;
  height: 300px;
}

.cir-border {
  border-radius: 100%;
  background-color: #06557c;
}

.space-left {
  margin-left: 45px;
}

.space-right {
  margin-right: 45px;
}

.vant-title {
  font-family: Poppins-Black;
  font-size: 2rem;
  text-align: center !important;
  color: #000000c0;
}

.vant-display-flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.vant-icon {
  font-size: 2.5rem;
  color: #1e90ff;
}

.con-mysign {
  margin-top: 100px;
  margin-bottom: 100px;
}

.con-hr {
  margin: 0 auto 0 auto;
  width: 40rem;
  color: rgba(0, 0, 0, 0.566);
}

.con-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.vant-box {
  background-color: #f0f0f0;
  width: 100%;
  height: auto;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.inv-br {
  margin-top: -10px;
}

#img2 {
  width: 445px;
  height: auto;
  border-radius: 0px;
  border: 000.1mm solid #c7c7c7;
}

.display-flex {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.display-flex-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.display-middle {
  display: flex;
  justify-content: normal;
  flex-direction: row;
  align-items: center;
}

h3.con-parag {
  font-family: Poppins-Medium;
  color: #0d346a;
  width: 1200px;
  font-size: 2rem;
  text-align: center;
}

.con-parag {
  text-align: center;
  margin: 0 auto 0 auto;
}

p.con-parag {
  font-family: Poppins-Light;
  width: 1200px;
  font-size: 2rem;
  text-align: center;
}

.vant-parag {
  font-family: Poppins-Regular;
  color: rgb(54, 54, 54);
  font-size: 1.4rem;
}

.aling-pp {
  margin-left: 5px;
}

.banner-limit {
  max-width: 1070px;
  margin: 0 auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
}

.home-middle {
  background-color: #d2d2d2;
  width: 200px;
}

.home-middle2 {
  background-color: rgb(255, 255, 255);
  height: 0;
}

.btn-button {
  width: 240px;
  border-radius: 10px;
}

.halver:hover {
  color: #000000c3 !important;
  background-color: #0000000e !important;
  border-color: #000000 !important;
  transition: 1s;
}

.halver2:hover {
  background-color: #ffffff19 !important;
  color: #000000a8 !important;
  border-color: #000000a4 !important;
  transition: 1s;
}

.bg-home-start {
  background-color: #ffffff;
  color: #091e3c;
  height: auto;
  background-image: url("../components/imagens/fundo.png");
  background-size: 1700px;
  background-position: center center;
  background-repeat: no-repeat;
}

.banner-title {
  font-family: Poppins-Black;
  width: 750px;
  margin-top: 0px;
  font-size: 3.3rem;
}

.button-display-flex {
  display: flex;
}

.white {
  color: #0b2a55;
  background-color: white !important;
  border: #0b2a55 1px solid;
  font-family: Poppins-Medium;
  font-weight: lighter;
  font-size: 16px;
  height: 50px;
}

.transparent {
  color: #ffffffe8 !important;
  background-color: #113c78;
  font-family: Poppins-Medium;
  font-weight: lighter;
  font-size: 16px;
  height: 50px;
}

.banner-intro {
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  height: 450px;
}

.banner-img {
  margin-bottom: 1px;
}

.banner-parag {
  font-family: Poppins-Regular;
  width: 550px;
  font-size: 1.2rem;
  margin-bottom: 30px;
  margin-top: 35px;
}

@font-face {
  font-family: "Poppins-Black";
  src: url("fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Quicksand";
  src: url("fonts/Quicksand_Light.otf");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("fonts/Poppins-Regular.ttf");
}

/* PRODUCTS CSS */

.product {
  max-width: 950px;
  overflow: hidden;
  height: auto;
  border: 1px solid #c7c7c7;
  padding: 15px;
  border-radius: 6px;
  margin: 15px;
  position: relative;
  transition: all 0.56s;
  box-shadow: 0px rgba(0, 0, 0, 0);
}
.product:hover {
  box-shadow: 2px 3px 19px 6px rgba(0, 0, 0, 0.142);
}
.product-flex {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.product-text {
  font-family: Poppins-Regular;
  padding: 5px;
  margin: 5px;
  padding: 6px;
  border-radius: 5px;
}
.product-button button,
a {
  font-family: Poppins-Medium;
  width: 180px;
  padding-inline: 0px;
  position: absolute;
  right: 6px;
  bottom: 5px;
  height: 36px;
  border-radius: 8px !important;
}
.product-img {
  height: 100%;
  width: auto;
}
.product-text h2 {
  font-family: Poppins-Medium;
}
.products-box {
  display: flex;
  justify-content: center;
  margin-top: 1.4rem;
  flex-direction: column-reverse;
  align-items: center;
}
@media (max-width: 860px) {
  .product {
    max-width: 850px;
    margin-inline: 10px;
  }

  @media (max-width: 860px) {
    .product {
      max-width: auto;
      margin-inline: 10px;
    }
    @media (max-width: 660px) {
      .product-flex {
        flex-direction: column;
      }
      .product {
        max-width: auto;
        margin-inline: 90px;
      }
      .product-button button,
      a {
        font-family: Poppins-Medium;
        width: 100%;
        position: inherit;
        height: 36px;
        border-radius: 8px !important;
      }
      @media (max-width: 490px) {
        .product {
          margin-inline: 40px;
        }
      }
      @media (max-width: 340px) {
        .product {
          margin-inline: 10px;
        }
      }
    }
  }
}

.simple-text-pad-flex {
  display: flex;
  justify-content: center;
  max-width: 1150px;
  align-items: center;
}
.flex-column-reverse {
  flex-direction: row-reverse !important;
}

.simple-text-pad {
  display: flex;
  margin: 2rem;
  justify-content: center;
}
.simple-text-pad img {
  height: auto;
  width: 50%;
  margin-inline: 60px;
  margin: 5px;
  border: -1px solid transparent;
  border-radius: 7px;
}
.simple-text-pad p {
  font-family: Poppins-Light;
  color: #3c3c3c !important;
  margin: 15px;
  font-size: 1rem;
}
.simple-text-pad h3 {
  font-family: Poppins-Black;
  margin: 15px;
  font-size: 1.9rem;
}
@media (max-width: 1020px) {
  .simple-text-pad-flex {
    flex-direction: column-reverse !important;
  }
  .simple-text-pad h5,
  h3,
  p {
    margin-inline: 20px !important;
    text-align: center;
  }
  .simple-text-pad img {
    width: 100% !important;
    margin: 10px;
    max-width: 750px;
  }
  .simple-text-pad img {
    width: 70% !important;
  }
  .text-area {
    margin: 0 auto 0 auto;
  }
  @media (max-width: 700px) {
    .simple-text-pad img {
      width: 90% !important;
    }
    .simple-text-pad h5,
    h3,
    p {
      margin-inline: 0px !important;
      text-align: center;
    }
  }
  @media (max-width: 400px) {
    .simple-text-pad img {
      width: 100% !important;
    }
    .simple-text-pad h5,
    h3,
    p {
      margin-inline: 0px !important;
      text-align: center;
    }
    .simple-text-pad p {
      font-size: 0.86rem;
    }
  }
}
.destach-area {
  color: white;
  padding: 30px;
  display: flex;
  box-shadow: rgb(0 0 0 / 72%) 0px 0px 30px 0px;
  justify-content: center;
  position: relative;
  z-index: 2;
  font-family: Poppins-Regular;
}
.destach-area h1 {
  font-weight: bolder;
  text-align: center;

  font-family: Poppins-Black;
}
.destach-area h2 {
  font-weight: bold;
  text-align: center;
}
.destach-area li {
  margin: 15px;
  display: block;
  text-align: center;
  margin-top: 15px;
}
.destach-area .flex {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  max-width: 1150px;
  margin-top: 60px;
}
.destach-area img {
  height: auto;
  width: 50%;
  margin-inline: 60px;
  margin: 5px;
  border: -1px solid transparent;
  border-radius: 7px;
}
.destach-area ul {
  max-height: 450px;
  padding: 4px;
  overflow: auto;
}
@media (max-width: 1020px) {
  .destach-area .flex {
    flex-direction: column-reverse !important;
    align-items: center;
  }
  .destach-area h5,
  h3,
  p {
    margin-inline: 20px !important;
    text-align: center;
  }
  .destach-area img {
    width: 100% !important;
    margin: 10px;
    max-width: 750px;
  }
  .destach-area img {
    width: 70% !important;
  }
  .text-area {
    margin: 0 auto 0 auto;
  }
  @media (max-width: 700px) {
    .destach-area img {
      width: 90% !important;
    }
    .destach-area h5,
    h3,
    p {
      margin-inline: 0px !important;
      text-align: center;
    }
  }
  @media (max-width: 490px) {
    .destach-area img {
      width: 100% !important;
    }
    .destach-area ul {
      overflow: auto;
      filter: brightness(1.2) !important;
      border-radius: 9px;
    }
    .destach-area h5,
    h3,
    p {
      margin-inline: 0px !important;
      text-align: center;
    }
    .destach-area p {
      font-size: 0.86rem;
    }
  }
}
.sign-button {
  font-family: Poppins-Light;
  font-size: 18px;
  width: 500px;
  height: 60px;
}
.sign-button:hover {
  background-color: white !important;
  font-weight: bold !important;
}
.button-sign-box {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

@media (max-width: 530px) {
  .sign-button {
    width: 290px !important;
  }
}

.integration-area h2 {
  font-family: Poppins-Medium;
  font-size: 38px;
  text-align: center;
  margin-bottom: 1.2rem;
}

.integration-area .d-flex {
  justify-content: center;
}
.integration-area .column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
}
.integration-area {
  max-height: 1200px;
  margin-top: 3.5rem;
}
.integration-area i {
  font-size: 3.4rem;
  margin-bottom: 5px;
}
.integration-area p {
  font-family: Poppins-Light;
  text-align: center;
  color: black;
  max-width: 250px;
}

.integration-area .d-flex {
  flex-wrap: wrap !important;
}

.clients .carousel {
  height: auto;
  padding-bottom: 40px;
  width: 100%;
}
.clients {
  margin-top: 7.4rem;
  box-shadow: rgb(0 0 0 / 72%) 0px 0px 30px 0px;
}
#carouselExampleIndicators {
  border-bottom: 1px solid white;
  filter: brightness(0.9);
}
.clients h2 {
  color: white;
  font-family: Poppins-Medium;
  text-align: center;
  padding-top: 30px;
}
.clients img {
  max-height: auto;
  text-align: center;
  max-width: 250px;
  height: auto;
  border-radius: 8px;
}
.clients .img-box {
  width: 100%;
  display: flex;
  height: 250px;
  margin-top: 40px;
  justify-content: center;
}
.reveal.ARtop.active,
.reveal.Rright.active,
.reveal.Rleft.active,
.reveal.Rtop.active {
  transform: translateX(0);
  transform: translateY(0);
  opacity: 1;
}
.reveal.Rtop {
  position: relative;
  transform: translateY(90px);
  opacity: 0;
  transition: all 1s ease;
}
.reveal.ARtop {
  position: relative;
  transform: translateY(10px);
  opacity: 0;
  transition: all 1s ease;
}

.reveal.Rright {
  position: relative;
  transform: translateX(-150px);
  opacity: 0;
  transition: all 1s ease;
}
.reveal.Rleft {
  position: relative;
  transform: translateX(-150px);
  opacity: 0;
  transition: all 1s ease;
}
.cards-box {
  border: #75757544 2px solid;
  border-radius: 6px;
  margin: 5px;
}
</style>

