import { http } from './config.js'

export default {
    getApi: () => {
        return http.get('product')
    },
    listApi: (ApiId) => {
        return http.get(`product/landing-page/${ApiId}`)
    },
    save: (customer) => {
        return http.post('customer', customer)
    },
    pix: (payment) => {
        return http.post('payment', payment)
    },
    pixStatus: (paymentInstallmentId) => {
    return http.get(`payment/installment/${paymentInstallmentId}`);

    }
}