<template>
  <div v-show="db3.config.pages.suport">
    <div
      class="doc-bg"
      :style="`
        background-image: url(${db3.suporte_tecnico.img});`"
    >
      <div class="bg-semi-transparent"></div>
      <h1>{{ db3.suporte_tecnico.title }}</h1>
      <i class="bi bi-caret-up-fill"></i>
    </div>
  </div>
  <div class="sup-box">
    <p class="sup-text" style="text-align: center; padding: 15px">
      {{ db3.suporte_tecnico.text }}
    </p>
  </div>
  <div>
    <div class="card-box">
      <div
        style="margin-top: 15px"
        class="card"
        v-for="load in db3.suporte_tecnico.dowloads"
        :key="load.index"
      >
        <div class="card-header">Downloads - Suporte Técnico</div>
        <div class="card-body">
          <h5 class="card-title">{{ load.title }}</h5>
          <p class="card-text">
            {{ load.description }}. <a :href="load.link">Baixar</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="Footer reveal Rtop">
    <GlobalFooter />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { data } from "../App.vue";
import GlobalFooter from "../components/GlobalFooter.vue";

export default defineComponent({
  name: "JRSuport",
  components: {
    GlobalFooter,
  },
  data() {
    return {
      db3: data,
      Palet: "DarkBlue",
    };
  },
});
</script>

<style scoped>
.accordion-button {
  background-color: white !important;
  --accordion-btn-focus-border-color: white !important;
  --accordion-btn-focus-box-shadow: white !important;
}
h1,
h2,
h3,
h4 {
  font-family: Poppins-Medium;
}
.doc-bg i {
  position: absolute;
  bottom: -65px;
  color: white;
  font-size: 100px;
  z-index: 5;
}
.doc-bg {
  height: 450px;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  background-attachment: fixed;
  position: relative;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
.doc-bg h1 {
  z-index: 5;
  font-size: 5rem;
  position: relative;
  color: white;
}
.Docs-box {
  padding: 130px;
}

@media (max-width: 850px) {
  .Docs-box {
    padding-inline: 40px;
  }
  .card {
    width: 90% !important;
  }
  @media (max-width: 750px) {
    .Docs-box {
      padding-inline: 10px !important;
    }
    .doc-bg h1 {
      font-size: 3.9rem !important;
      text-align: center;
    }
  }
}

.bg-semi-transparent {
  background-color: rgba(0, 0, 0, 0.573);
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 450px;
}
.sup-text {
  padding: 15px;
  width: 850px;
  text-align: center;
  font-family: "Poppins-Regular";
}
.sup-box {
  display: flex;
  margin-top: 40px;
  justify-content: center;
}

.card-box {
  display: flex;
  margin-top: 5px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.card {
  width: 800px;
  margin-inline: 20px;
}
</style>
